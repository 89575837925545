<template>
	<div class="main">
		<div class="row1">
			<div class="row row1-son">
				<div class="row1-son-title">{{infoArr.title}}</div>
				<div class="row1-son-info">
					<div class="row1-son-info-left">{{infoArr.address}} | {{infoArr.type}} {{infoArr.department}}</div>
					<div class="row1-son-info-right">发布时间：2021-04-29</div>
				</div>
			</div>
		</div>
		<div class="row2">
			<div class="row row2-son">
				<div class="row2-son-box">
					<div class="row2-son-box-title">上传</div>
					<div>
						<div class="info-label">
							<div>上传简历</div>
							<div>*</div>
						</div>
						<el-upload class="upload-demo" :action="actionUrl" :on-change="handleChange"
							:file-list="fileList">
							<!-- <el-button size="small" type="primary">上传简历</el-button> -->
							<button class="upload-demo-btn">上传简历</button>
							<div slot="tip" class="el-upload__tip">
								支持pdf、doc、xls、ppt、docx、pptx、wps、html、jpg、jpeg、png、rtf、txt等简历格式
							</div>
						</el-upload>
						<div class="info-label accessory">
							<div>上传附件</div>
						</div>
						<el-upload class="upload-demo" :action="actionUrl"
							:on-change="handleChange1" :file-list="fileList1">
							<button class="upload-demo-btn">上传附件</button>
							<div slot="tip" class="el-upload__tip">
								支持文档、图片、压缩包、视频、音频、设计文件等格式文件，所有附件大小总和不超过50MB
							</div>
						</el-upload>
					</div>
					<div class="information-box">
						<div class="information-box-title">个人信息</div>
						<div class="information-box-main">
							<div class="information-box-main-name">
								<div class="information-box-main-label">
									<span>姓名</span><span>&nbsp;*</span>
								</div>
								<input type="text" class="information-box-main-name-input" placeholder="请输入您的姓名" />
							</div>
							<div class="information-box-main-phone">
								<div class="information-box-main-label">
									<span>手机号</span><span>&nbsp;*</span>
								</div>
								<input type="text" class="information-box-main-name-input" placeholder="请输入您的手机号" />
							</div>
							<div class="information-box-main-email">
								<div class="information-box-main-label">
									<span>邮箱</span><span>&nbsp;*</span>
								</div>
								<input type="text" class="information-box-main-name-input" placeholder="请输入您的邮箱" />
							</div>
						</div>
					</div>
					<div class="information-btn">
						<div>提交</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				actionUrl: "/portal/upload",
				fileList: [], //简历文件
				fileList1: [], //附件文件
				infoArr: {
					name: "",
					phone: "",
					email: "",
				},
				rules: {
					name: [{
						required: true,
						message: "请输入您的姓名",
						trigger: "blur",
					}, ],
					phone: [{
							required: true,
							message: "请输入您的手机号",
							trigger: "blur",
						},
						{
							pattern: /^1[3-9]\d{9}$/,
							message: "手机号格式错误",
							trigger: "blur",
						},
					],
					email: [{
							required: true,
							message: "请输入您的邮箱",
							trigger: "blur",
						},
						{
							pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
							message: "请输入正确的邮箱账号",
							trigger: "blur",
						},
					],
				},
			};
		},
		created() {
			//接收页面跳转携带过来的参数
			var Info = this.$route.params.info;
			console.log(Info);
			this.infoArr = Info;
			this.actionUrl = this.baseUrl + this.actionUrl;
		},
		methods: {
			handleChange(file, fileList) {
				console.log(file);
				console.log(fileList);
				this.fileList = fileList.slice(-3);
			},
			handleChange1(file, fileList) {
				this.fileList1 = fileList.slice(-3);
			},
		},
	};
</script>

<style scoped>
	body,html{
		margin: 0;
		border: 0;
		padding: 0;
		width: 100%;
	}
	.main{
		width: 100%;
		max-width: 1920px;
		margin:  0 auto;
	}
	.row {
		width: 1200px;
		margin: 0 auto;
	}

	.row1 {
		width: 100%;
		background: #ffffff;
	}

	.row1-son {}

	.row1-son-title {
		padding-top: 58px;
		font-size: 36px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #363f4e;
	}

	.row1-son-info {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #363f4e;
		padding-top: 31px;
		display: flex;
		justify-content: space-between;
		padding-bottom: 52px;
	}

	.row2 {
		width: 100%;
		height: 1072px;
		background: #f7f8fb;
	}

	.row2-son {
		padding-top: 68px;
	}

	.row2-son-box {
		padding: 0 44px;
		background: #ffffff;
		box-shadow: 0px 1px 20px 0px rgba(115, 124, 139, 0.2);
	}

	.row2-son-box-title {
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
		padding-top: 57px;
		padding-bottom: 14px;
		border-bottom: 1px solid #e5e5e5;
	}

	.upload-demo-btn {
		width: 348px;
		height: 48px;
		background: #dddfe3;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #363f4e;
		text-align: center;
		line-height: 48px;
		border: 0;
	}

	.info-label {
		width: 90px;
		height: 46px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
		display: flex;
		justify-content: left;
		align-items: center;
	}

	.info-label>div:nth-child(2) {
		margin-left: 5px;
		color: #ff0000;
	}

	.accessory {
		margin-top: 41px;
	}

	.information-box-title {
		margin-top: 83px;
		padding-bottom: 14px;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
		border-bottom: 1px solid #e5e5e5;
	}

	.information-box-main-name-input {
		padding-left: 20px;
		width: 348px;
		height: 48px;
		background: #f7f8fb;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ababab;
		border: 0;
		outline: none;
	}

	.information-box-main {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-bottom: 47px;
		border-bottom: 1px solid #e5e5e5;
	}

	.information-box-main>div {
		width: 48%;
	}

	.information-box-main-label {
		margin-top: 32px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-bottom: 12px;
	}

	.information-box-main-label>span:nth-child(2) {
		color: #ff0000;
	}

	.information-btn {
		width: 100%;
		padding-top: 48px;
		padding-bottom: 48px;
	}

	.information-btn>div {
		width: 120px;
		height: 40px;
		background: #4285f4;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
		text-align: center;
		line-height: 40px;
		margin: 0 auto;
		cursor: pointer;
	}
</style>
