// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Axios from "axios";
Vue.prototype.baseUrl = "https://www.fzditian.com/gwapi";
// Vue.prototype.baseUrl = 'http://192.168.51.84:9616'
Vue.prototype.$axios = Axios;
Vue.use(ElementUI);
Vue.config.productionTip = false;
// Vue.prototype.$axios.defaults.baseURL = Vue.prototype.baseUrl
/* eslint-disable no-new */
if (process.env.NODE_ENV == "development") {
  Vue.prototype.$axios.defaults.baseURL = "/api";
} else if (process.env.NODE_ENV == "production") {
  Vue.prototype.$axios.defaults.baseURL = Vue.prototype.baseUrl;
}
Vue.prototype.$axios.defaults.timeout = 20000;
Vue.prototype.$axios.interceptors.request.use(
  (config) => {
    // console.log(config)
    // 自定义header信息（比如token）
    // console.log("请求拦截器添加userId-----------",sessionStorage.userId)
    if (!config.headers["gwId"]) {
      config.headers["gwId"] = "1";
    }
    // console.log(config)
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
