<template>
	<div class="box">
		<div class="row header">
			<div class="header-left-box"></div>
			<div style="display: flex; flex-wrap: nowrap; align-items: center">
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
					router>
					<el-menu-item index="Home">首页</el-menu-item>
					<el-menu-item index="Product">
						<template slot="title">
							产品 <img src="../../static/img/new.png" />
						</template>
					</el-menu-item>
					<el-menu-item index="plan">方案</el-menu-item>
					<el-menu-item index="moralExample">案例</el-menu-item>
					<el-menu-item index="Information">资讯动态</el-menu-item>
					<el-menu-item index="recruitment">岗位招聘</el-menu-item>
					<el-menu-item index="Details">关于帝天</el-menu-item>
				</el-menu>
				<div class="btn" @click="goYuyue">预约交流</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		computed: {
			activeIndex() {
				return this.$route.path.replace('/', '')
			}
		},
		created() {

		},
		methods: {
			// 点击预约跳转到预约表单位置
			goYuyue() {
				window.scrollTo(0, document.documentElement.scrollHeight - document.documentElement.clientHeight)
			},
			handleSelect(key, keyPath) {
			},
		}
	}
</script>

<style scoped>
	body,html{
		margin: 0;
		border: 0;
		padding: 0;
		width: 100%;
	}

	.row {
		width: 1200px;
		margin: 0 auto;
	}

	.box {
		width: 100%;
		max-width: 1920px;
		margin:  0 auto;
		position: sticky;
		top: 0;
		border-bottom: 1px solid #e9ebf3;
		z-index: 150;
		background-color: #fff;
		border-bottom: 1px solid #e9ebf3;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.header-left-box {
		float: left;
		/* margin-top: 18px; */
		height: 30px;
		width: 160px;
		background-image: url(../../static/img/logo-ditian-b.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.btn {
		width: 86px;
		height: 30px;
		background: #4285f4;
		border-radius: 2px;
		font-size: 14px;
		font-family: PingFang SC Medium;
		color: #ffffff;
		text-align: center;
		line-height: 30px;
		cursor: pointer;
		margin-left: 30px;
	}
</style>
