<template>
	<div class="main">
		<div class="row1">
			<img style="width: 100%;" src="../../../static/img/banner/banner_jdal.3301899.jpg" />
			<div>
				<p>
					经典案例

				</p>
				<p>
					在众多不同行业中寻找适合您企业的最佳实践标杆
				</p>
			</div>
		</div>
		<div class="row2" v-if="classic.length > 0">
			<div class="row row2-son">
				<div class="row2-son-title">
					经典案例
				</div>
			</div>
		</div>
		<div class="row3" v-if="classic.length > 0">
			<div class="row row3-son">
				<div :class="yrFlag == index ? 'row3-son-box row3-son-box1' : 'row3-son-box'"
					v-for="(item, index) in classic.length > 4 ? 4 : classic.length" :key="index" @click="classicClick(index)">
					<div class="row3-son-box-icon">
						<img :src="classic[index].logo_url" alt="">
					</div>
					<div class="row3-son-box-title">
						主营 | {{ classic[index].main_content }}
					</div>
				</div>
			</div>
		</div>
		<div class="row4" v-if="classic.length > 0">
			<div class="row row4-son">
				<div class="classicBox" v-for="(item, index) in classic" :key="index" v-show="yrFlag == index">
					<div class="row4-son-left">
						<div class="row4-son-left-row1">
							{{ item.name }}
						</div>
						<div class="row4-son-left-row2">
							主营 | {{ item.main_content }}
						</div>
						<div class="row4-son-left-row3">
							核心需求：{{ item.technical_feature }}
						</div>
						<div class="row-son-left-row4" v-html="item.particulars">
						</div>
						<div class="row-son-left-row5" @click="goMoralExampleDetails(item.id)">
							了解详情
						</div>
					</div>
					<div class="row4-son-right">
						<img :src="item.picture_url" alt="">
					</div>
				</div>

			</div>
		</div>
		<div class="row5">
			<div class="row row5-son">
				<div class="row5-son-title">
					全部案例
				</div>
				<div class="row5-son-row2-div" v-for="(item, index) in caseArr" :key="index"
					@click="goMoralExampleDetails(item.id)">
					<div class="row5-son-row2-div-img row5-son-row2-div-img1"
						:style="'background-image:url(' + item.picture_url + ');background-size: 100% 100%;background-repeat: no-repeat;'">
					</div>
					<div class="row5-son-row2-div-logo">
						<img :src="item.logo_url" />
					</div>
					<div class="row5-son-row2-div-text1">
						{{ item.name }}
					</div>
					<div class="row5-son-row2-div-text2" v-html="item.particulars">
					</div>
					<div class="row5-son-row2-div-text4"></div>
					<div class="row5-son-row2-div-text5">
						主营：{{ item.main_content }}
					</div>
				</div>
				<div class="row5-son-btn" v-if="page.total > 0">
					<el-pagination background layout="prev, pager, next" :total="page.total" :pageSize="page.pageSize"
						@current-change="change">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			yrFlag: 0,
			caseArr: [], //所有案例
			page: {
				total: 10, //总条数
				pageNumber: 1, //当前页
				pageSize: 10, //每页个数
			},
			classic: [] // 经典案例
		}
	},
	created() {
		//获取案例列表
		this.$axios.get('/case/list', {
			params: {
				pageSize: 3
			}
		}).then(res => {
			if (res.status == 200) {
				this.caseArr = res.data.list;
				this.page.total = res.data.total;
				this.page.pageSize = res.data.pageSize;
				this.caseArr.forEach(item => {
					item.picture_url = this.baseUrl + item.picture_url;
					item.logo_url = this.baseUrl + item.logo_url;
				})
			}
		}).catch(err => {
			console.log(err)
		})
		this.$axios.get('/case/list').then(res => {
			if (res.status == 200) {
				res.data.list.forEach(item => {
					if (item.case_type == '经典案例') {
						if (this.classic.length >= 4) {
							return;
						} else {
							this.classic.push(item);
						}
					}
				})
				this.classic.forEach(it => {
					it.picture_url = this.baseUrl + it.picture_url;
					it.logo_url = this.baseUrl + it.logo_url;
				})
				console.log(this.classic)
			}
		}).catch(err => {
			console.log(err)
		})
	},
	methods: {
		//点击切换经典案例方法
		classicClick(index) {
			this.yrFlag = index;
		},
		//获取案例列表
		getList(total, pageNumber, pageSize) {
			this.$axios.get('/case/list', {
				params: {
					pageSize: pageSize,
					total: total,
					pageNumber: pageNumber
				}
			}).then(res => {
				if (res.status == 200) {
					this.caseArr = res.data.list
					this.page.total = res.data.total
					this.page.pageSize = res.data.pageSize
					this.page.pageNumber = res.data.pageNumber
					this.caseArr.forEach(item => {
						item.picture_url = this.baseUrl + item.picture_url;
						item.logo_url = this.baseUrl + item.logo_url;
					})
				}
			}).catch(err => {
				console.log(err)
			})
		},
		//翻页功能
		change(e) {
			this.getList(this.page.total, e, this.page.pageSize)
		},
		goMoralExampleDetails(id) {
			sessionStorage.setItem('id', id)
			this.$router.push({
				name: 'moralExampleDetails',
				params: {
					id: id
				}
			})
		}
	}
}
</script>

<style scoped>
body,
html {
	margin: 0;
	border: 0;
	padding: 0;
	width: 100%;
}

.main {
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
}

.row {
	width: 1200px;
	margin: 0 auto;
}

.row1 {
	width: 100%;
	position: relative;
}

.row1>div {
	position: absolute;
	top: 20%;
	z-index: 9;
	color: #ffffff;
	width: 100%;
	text-align: center;
}

.row1>div>P:nth-child(1) {
	font-size: 36px;
}

.row1>div>P:nth-child(2) {
	font-size: 20px;
}

.row2 {
	width: 100%;
}

.row2-son {}

.row2-son-title {
	width: 100%;
	text-align: center;
	padding-top: 70px;
	font-size: 30px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #20242B;
	padding-bottom: 43px;
}

.row3 {
	width: 100%;
	height: 140px;
	background: #F8FAFD;
}

.row3-son {
	display: flex;
	justify-content: center;
}

.row3-son-box {
	width: 300px;
	height: 140px;
	background: #F8FAFD;
}

.row3-son-box1 {
	background: #FFFFFF;
	box-shadow: 0px 6px 20px 0px rgba(115, 124, 139, 0.1);
}

.row3-son-box-icon {
	padding-top: 13px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.row3-son-box-icon>img {
	width: 72px;
	height: 72px;
}

.row3-son-box-title {
	width: 100%;
	padding-top: 24px;
	text-align: center;
	color: #737C8B;
}

.row4 {
	width: 100%;
	height: 428px;
	background: #F8FAFD;
}

.row4-son {
	height: 100%;
	display: flex;
	justify-content: space-between;
}

.row4-son-left {
	width: 45%;
	height: 100%;
}

.row4-son-left-row1 {
	padding-top: 34px;
	font-size: 24px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #20242B;
}

.row4-son-left-row2 {
	padding-top: 27px;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #737C8B;
}

.row4-son-left-row3 {
	padding-top: 27px;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #20242B;
}

.row-son-left-row4 {
	width: 520px;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #737C8B;
	line-height: 26px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	padding-top: 40px;
}

.row-son-left-row5 {
	margin-top: 45px;
	width: 120px;
	height: 40px;
	background: #4285F4;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 25px;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
	position: absolute;
	bottom: 34px;
}

.row4-son-right {
	width: 55%;
	height: 100%;
	display: flex;
	justify-content: right;
	align-items: center;
}

.row4-son-right>img {
	width: 665px;
	height: 360px;
}

.row5-son {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.row5-son-row2-div {
	width: 336px;
	height: 406px;
	position: relative;
	box-shadow: 0px 4px 10px 0px rgba(155, 176, 212, 0.2);
	border-radius: 3px;
	margin-bottom: 35px;
	transition: all .6s;
}

.row5-son-row2-div:hover {
	transform: scale(1.1);
}

.row5-son-row2-div-img {
	width: 336px;
	height: 190px;
	/* background-image: url(../../../static/img/p-1cake.jpg); */
	background-size: 100%;
	background-repeat: no-repeat;
}

.row5-son-row2-div-logo {
	position: absolute;
	top: 150px;
	left: 20px;
	width: 72px;
	height: 72px;
	background: #FFFFFF;
	border-radius: 36px;
	box-shadow: 0px 4px 10px 0px rgba(155, 176, 212, 0.2);
}

.row5-son-row2-div-logo>img {
	width: 100%;
	height: 100%;
}

.row5-son-row2-div-logo2 {
	position: absolute;
	top: 124px;
	left: 20px;
	width: 72px;
	height: 72px;
	background: linear-gradient(45deg, #3B6EE9, #4197FF);
	box-shadow: 0px 2px 10px 0px rgba(34, 24, 21, 0.1);
	border-radius: 36px;
}

.row5-son-row2-div-logo3 {
	position: absolute;
	top: 124px;
	left: 20px;
	width: 72px;
	height: 72px;
	background: #FFFFFF;
	box-shadow: 0px 2px 10px 0px rgba(34, 24, 21, 0.1);
	border-radius: 36px;
}

.row5-son-row2-div-logo>img {
	border-radius: 36px;
}

.row5-son-row2-div-logo2>img {
	border-radius: 36px;
}

.row5-son-row2-div-logo3>img {
	border-radius: 36px;
}

.row5-son-row2-div-text1 {
	margin-top: 56px;
	margin-left: 20px;
	height: 17px;
	font-size: 18px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #333333;
	line-height: 0px;
}

.row5-son-row2-div-text2 {
	margin-top: 15px;
	margin-left: 20px;
	width: 270px;
	height: 55px;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #999999;
	overflow: hidden;
}

.row5-son-row2-div-text3 {
	margin-top: 9px;
	margin-left: 20px;
	width: 300px;
	height: 15px;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #999999;
	line-height: 0px;
}

.row5-son-row2-div-text4 {
	width: 292px;
	height: 1px;
	background: #E5E5E5;
	margin: 21px auto 0 auto;
}

.row5-son-row2-div-text6 {
	width: 292px;
	height: 1px;
	background: #E5E5E5;
	margin: 46px auto 0 auto;
}

.row5-son-row2-div-text5 {
	margin: 26px 0 0 19px;
	height: 14px;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #333333;
	line-height: 0px;
}

.row5-son-title {
	font-size: 30px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #20242B;
	width: 100%;
	text-align: center;
	padding-top: 68px;
	padding-bottom: 60px;
}

.row5-son-btn {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 98px;
}

.classicBox {
	display: flex;
	justify-content: space-between;
	width: 100%;
	animation: lefteaseinAnimate 1s ease 1;
	animation-fill-mode: forwards;
	transition: all 0.6s;
}

/* 左右移动动画效果 */
@keyframes lefteaseinAnimate {
	0% {
		transform: translateX(-100px);
		opacity: 0;
	}

	100% {
		transform: translateX(0px);
		opacity: 1;
	}

}</style>
