<template>
	<div class="main">
		<div class="row1">
			<img style="width: 100%;" src="../../../static/img/fzlc.jpg" />
			<div class="row1-son1">易销微信小程序商城3.0</div>
			<div class="row1-son2">
				新一代电商解决方案，打造您的“私域流量”人专属商城
			</div>
			<div class="row1-son3">
				<div @click="goYuyue()">预约交流</div>
			</div>
		</div>
		<div class="row2">
			<div class="row row2-son">
				<div class="row2-son-title">多领域解决方案</div>
				<div class="row2-son-label">
					<ul>
						<li :class="
							index == key
							? 'row2-son-label-ul-li'
							: 'row2-son-label-ul-li row2-son-label-ul-li1'
							" v-for="(item, key) in ulArr" :key="key" @click="clickUl(key)">
							<div v-if="index == key" class="dot"></div>
							<div v-if="index != key" class="no-dot"></div>
							{{ item.name }}
						</li>
					</ul>
				</div>
				<div class="row2-son-main">
					<div class="row2-son-main-left">
						<div @mouseover="move(key)" v-for="(item, key) in ulArr" :key="key" :class="
							subscript == key
							? 'row2-son-main-left-div'
							: 'row2-son-main-left-div1'">
						</div>
					</div>
					<div class="row2-son-main-right">
						<div id="row2-son-main-right-box">
							<div class="row2-son-main-right-box-son" v-for="(item, key) in ulArr" :key="key"
								:style="'background-image:url(' +item.bgImg  + ') ;background-repeat: no-repeat;background-size: 100% 100%;'">
								<div class="row2-son-main-right-box-son-left">
									<div class="row2-son-main-right-box-son-left-title">
										{{ item.title }}
									</div>
									<div class="row2-son-main-right-box-son-left-text">
										{{item.main}}
									</div>
								</div>
								<div class="row2-son-main-right-box-son-right">
									<img src="../../../static/img/tx1.png" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row3">
			<div class="row row3-son">
				<div class="row3-son-title">产品特色</div>
				<div class="row3-son-main">
					<div class="row3-son-main-box" v-for="(item,index) in featuresArr">
						<div class="row3-son-main-box-left" v-if="index%2 != 0">
							<img :src="item.img" alt="" />
						</div>
						<div class="row3-son-main-box-right">
							<div class="row3-son-main-box-right-title">
								<div class="row3-son-main-box-right-rod"></div>
								<div class="row3-son-main-box-right-text">{{item.title}}</div>
							</div>
							<div class="row3-son-main-box-right-div2">
								{{item.text}}
							</div>
							<div class="row3-son-main-box-right-div3">
								{{item.content}}
							</div>
							<div class="row3-son-main-box-right-div4">
								<div @click="goYuyue">预约交流</div>
							</div>
						</div>
						<div class="row3-son-main-box-left" v-if="index%2 == 0">
							<img :src="item.img" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row4">
			<div class="row row4-son">
				<div class="row4-son-main">
					<div>
						<div class="row4-son-main-left-row1">
							<div class="row4-son-main-left-title"></div>
							<div class="row4-son-main-left-text" v-for="(item, key) in slideArr" v-if="animation == key"
								:key="key">
								<span id="row4-son-main-left-text">{{ item.title }}</span>

							</div>
						</div>
						<div class="row4-son-main-left-row2" v-for="(item, key) in slideArr" v-if="animation == key"
							:key="key">

							<span id="row4-son-main-left-row2-main">{{ item.main }}</span>
						</div>
						<div class="row4-son-main-left-btn" @click="goYuyue()">
							预约交流
						</div>
					</div>
					<div class="row4-son-main-right">
						<div class="row4-son-main-right-img" v-for="(item, key) in slideArr" v-if="animation == key">
							<img :src="item.img" />
						</div>
						<div class="row4-son-main-right-box"></div>
					</div>
				</div>
			</div>
			<div class="row4-step">
				<div class="row4-step-article" ref="article"></div>
				<div class="row4-step-son" @click="schedule(1)"></div>
				<div class="row4-step-son row4-step-son1" @click="schedule(2)"></div>
				<div class="row4-step-son row4-step-son2" @click="schedule(3)"></div>
				<div class="row4-step-son row4-step-son3" @click="schedule(4)"></div>
				<div class="row4-step-son row4-step-son4" @click="schedule(5)"></div>
				<div class="row4-step-son row4-step-son5" @click="schedule(6)"></div>
				<div class="row4-step-text">商品展示</div>
				<div class="row4-step-text row4-step-text1">订单管理</div>
				<div class="row4-step-text row4-step-text2">客户引流</div>
				<div class="row4-step-text row4-step-text3">物流互动</div>
				<div class="row4-step-text row4-step-text4">客户管理</div>
				<div class="row4-step-text row4-step-text5">提现快捷</div>
			</div>
		</div>
		<div class="row5">
			<div class="row row5-son">
				<div class="row5-son-title">
					<div>版本套餐</div>
					<div>根据您的经营需求情况，选择合适的套餐版本</div>
				</div>
				<div class="row5-son-main">
					<div class="row5-son-main-box" v-for="(item, index) in packageArr" :key="index">
						<div class="row5-son-main-box-row1">
							<div>{{ item.title }}</div>
							<div>{{ item.price }}</div>
						</div>
						<div class="row5-son-main-box-row2">
							{{ item.introduce }}
						</div>
						<div class="row5-son-main-box-row3">
							<div class="row5-son-main-box-row3-box">
								<ul>
									<li v-for="(it, key) in item.content" :key="key">
										<div :class="index == 0 && key>4? 'li-left1' 
										:index == 1 && key>6 ?'li-left1' : 'li-left'"></div>
										<div
											:class="index == 0 && key>4? 'color-gray' :index == 1 && key>6 ?'color-gray' : ''">
											{{ it }}
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<img src="../../../static/img/cp/tcdt.jpg" />
		</div>
		<div class="row6">
			<div class="row row6-son">
				<div class="row6-son-title">
					<div>企业案例</div>
					<div>适用于多个行业使用，打造您的专属商城</div>
				</div>
				<div class="row6-son-main">
					<div class="row6-son-main-row1">
						<div>
							<div>200+</div>
							<div>软著与技术专利</div>
						</div>
						<div>
							<div>500+</div>
							<div>企业客户</div>
						</div>
						<div>
							<div>800+</div>
							<div>项目案例</div>
						</div>
					</div>
					<div class="row6-son-main-row2">
						<div class="row6-son-main-row2-box">
							<div class="box-top backgroudImg"></div>
							<div class="box-bottom backgroudImg1">
							</div>
						</div>
						<div class="row6-son-main-row2-box">
							<div class="complete-box backgroudImg2">
	<!-- 							<div>壹刻食品</div>
								<div>实现向新零售的快速转型</div>
								<div class="complete-box-son3">
									<div @click="goMoralExample()">
										<img src="../../../static/img/right.png" />
									</div>
								</div> -->
							</div>
						</div>
						<div class="row6-son-main-row2-box">
							<div class="box-top backgroudImg3">
							</div>
							<div class="box-bottom backgroudImg4">
							</div>
						</div>
						<div class="row6-son-main-row2-box">
							<div class="box-bottom backgroudImg5">
							</div>
							<div class="box-top backgroudImg6">
							</div>
						</div>
					</div>
				</div>
				<div class="row6-son-btnBox">
					<div class="row6-son-btn" @click="goMoralExample">更多案例</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				featuresArr: [{
						title: '零距离',
						text: '没有多余的中间环节，无阻碍',
						content: '每个用户都可以直接访问',
						img: require('../../../static/img/cp/ljl.jpg')
					},
					{
						title: '超低成本',
						text: '没有多余的中间环节，无阻碍',
						content: '与用户接触的成本趋于0',
						img: require('../../../static/img/cp/cdcb.jpg')
					},
					{
						title: '高度互动',
						text: '没有多余的中间环节，无阻碍',
						content: '能够与用户频繁、高效和多维互动',
						img: require('../../../static/img/cp/gdhd.jpg')
					},
					{
						title: '私有化',
						text: '没有多余的中间环节，无阻碍',
						content: '用户聚集在独立的站点或独立的应用程序和其他运营商，私人运营商',
						img: require('../../../static/img/cp/syh.jpg')
					},
					{
						title: '及时交付',
						text: '没有多余的中间环节，无阻碍',
						content: '用户对平台的信任的较高，粘性强，始终注意流通信息的主要发布',
						img: require('../../../static/img/cp/jsjf.jpg')
					},
					{
						title: '牢固关系',
						text: '没有多余的中间环节，无阻碍',
						content: '在你的专业领域，客户无条件地信任你，你推荐什么，客户买什么 ',
						img: require('../../../static/img/cp/lggx.jpg')
					}
				], //特色数组
				title: '商品展示',
				animation: 0,
				main: '易销小程序是你专属的营销工具，后台实时同步更新客户购买信息，高效响应。',
				slideArr: [{
						id: 1,
						title: '商品展示',
						main: '展示商品、各种优惠信息以及商品的详细信息。无论主营种类是什么，服务类主营也可明码标价当做商品上传。',
						img: require('../../../static/img/cp/spzs.png')
					},
					{
						id: 2,
						title: '订单管理',
						main: '易销小程序是你专属的营销工具，后台实时同步更新客户购买信息，对接pos打单功能，高效响应。',
						img: require('../../../static/img/cp/ddgl.png')
					},
					{
						id: 3,
						title: '客户引流',
						main: '每日可轻松查看客户行为、流量，以及详细客户数据，用于高频消费优质客户收集反馈。',
						img: require('../../../static/img/cp/khyl.png'),
						content:''
					},
					{
						id: 4,
						title: '物流互动',
						main: '客户在购买商品后，可在后台简易操作物流动态，以及订单信息。客户收到商品后遇到疑问或者好评皆可提交，后台反馈、回复。',
						img: require('../../../static/img/cp/wlhd.png'),
						content:''
					},
					{
						id: 5,
						title: '客户管理',
						main: '每日可轻松查看客户行为、流量，以及详细客户数据，用于高频消费优质客户收集反馈。',
						img: require('../../../static/img/cp/khgl.png'),
						content:''
					},
					{
						id: 6,
						title: '提现快捷',
						main: '无论主营消费频率高低，即便主营为便利店，都可实现申请提现起24小时内到账，实现T+1提现效率。',
						img: require('../../../static/img/cp/kjtx.png'),
						content:''
					},
				], //幻灯片数组
				index: 0,
				subscript: 0,
				ulArr: [
					{
						name: '乐享茶业',
						title:'乐享茶业',
						bgImg: require('../../../static/img/cp/lxcy.6de3261.jpg'),
						main: '易销·乐享茶业是一款汇聚了海量茶类、茶器、茶具的小程序,设有限时专场,用户可以随时随地抢购好茶,购买茶具'
					},
					{
						name: '生鲜零售',
						title:'生鲜零售',
						bgImg: require('../../../static/img/cp/sxls.196fed2.jpg'),
						main : '易销·生鲜零售是一款面对线下门店全渠道新零售解决方案。帮助商家扩增量，盘存量、经营提效、带来更多生意'
					},
					{
						name: '有品家居',
						title:'有品家居',
						bgImg: require('../../../static/img/cp/ypjj.febdc49.jpg'),
						main : '易销·有品家居为您提供最优惠的精品家居信息。打造一站式家居商场,并提供贵宾式专属服务'
					},
					{
						name: '优选服饰',
						title:'优选服饰',
						bgImg: require('../../../static/img/cp/yxfs.24e5d28.jpg'),
						main : '易销·优选服饰是一款基于互联网打造的线上服饰销售平台。免费拼团，秒杀爆款等多种营销方式引爆销量'
					},
					{
						name: '魅力珠宝',
						title:'魅力珠宝',
						bgImg: require('../../../static/img/cp/mlzb.ee6172d.jpg'),
						main : '易销·魅力珠宝是专业的时尚珠宝首饰网上购物商城。集数万个品牌优质商品,便捷、诚信的服务'
					},
					{
						name: '聚友餐饮',
						title:'聚友餐饮',
						bgImg: require('../../../static/img/cp/jycy.12b262d.jpg'),
						main : '易销·聚友餐饮提供丰富、全面的,当下门店的餐饮信息，助力餐饮业加速“回暖”,让顾客乐享优惠'
					},
				],
				packageArr: [
					{
						title: '微商城基础版',
						introduce: '适合个人或三个人以下运营的团队开店,享受订单额度10000单/年,满足商品销售、推广营销等基础经营需求',
						price: '¥ 6800/年',
						content: [
							'快速开店，打通全网社交平台',
							'一键生成微信小程序或百度小程序',
							'3个帐号权限',
							'享受订单额度 10000 单/年',
							'多人拼团、秒杀、满减/送等营销工具',
							'销售员、好友瓜分券、砍价0元购裂变玩法',
							'会员储值等复购工具',
							'推广分析、单品分析、热力图等数据工具',
							'社区团购、定金膨胀、周期购等玩法',
						],
					},
					{
						title: '微商城专业版',
						introduce: '适合成长型电商、门店商家，享受订单额度20000单/年,满足推广获客、复购增购等核心经营需求',
						price: '¥ 12800/年',
						content: [
							'快速开店，打通全网社交平台',
							'一键生成微信小程序或百度小程序',
							'10个帐号权限',
							'享受订单额度 20000 单/年',
							'多人拼团、秒杀、满减/送等营销工具',
							'销售员、好友瓜分券、砍价0元购裂变玩法',
							'会员储值等复购工具',
							'推广分析、单品分析、热力图等数据工具',
							'社区团购、定金膨胀、周期购等玩法',
						],
					},
					{
						title: '微商城定制版',
						introduce: '适合规模化扩张，有多个经营场景需求的成熟商家，享受订单额度40000单/年,满足创新营销玩法等深度经营需求',
						price: '价格面议',
						content: [
							'快速开店，打通全网社交平台',
							'一键生成微信小程序或百度小程序',
							'30个帐号权限',
							'享受订单额度 40000 单/年',
							'多人拼团、秒杀、满减/送等营销工具',
							'销售员、好友瓜分券、砍价0元购裂变玩法',
							'会员储值等复购工具',
							'推广分析、单品分析、热力图等数据工具',
							'社区团购、定金膨胀、周期购等玩法',
						],
					},
				],
			}
		},
		methods: {
			// 点击前往案例页
			goMoralExample() {
				this.$router.push('moralExample')
			},
			// 点击预约跳转到预约表单位置
			goYuyue() {
				window.scrollTo(
					0,
					document.documentElement.scrollHeight -
					document.documentElement.clientHeight
				)
			},
			schedule(index) {
				console.log(index)
				switch (index) {
					case 1:
						this.$refs.article.style.width = '29%'
						this.title = this.slideArr[0].title
						this.main = this.slideArr[0].main
						this.animation = 0
						break;
					case 2:
						this.$refs.article.style.width = ' 42%'
						this.title = this.slideArr[1].title
						this.main = this.slideArr[1].main
						this.animation = 1
						break;
					case 3:
						this.$refs.article.style.width = '55%'
						this.title = this.slideArr[2].title
						this.main = this.slideArr[2].main
						this.animation = 2
						break;
					case 4:
						this.$refs.article.style.width = '69% '
						this.title = this.slideArr[3].title
						this.main = this.slideArr[3].main
						this.animation = 3
						break;
					case 5:
						this.$refs.article.style.width = '82%'
						this.title = this.slideArr[4].title
						this.main = this.slideArr[4].main
						this.animation = 4
						break;
					case 6:
						this.$refs.article.style.width = '94%'
						this.title = this.slideArr[5].title
						this.main = this.slideArr[5].main
						this.animation = 5
						break;
				}
				document.getElementById('row4-son-main-left-text').style.animation = 'lefteaseinAnimate 1s ease 1'
				document.getElementById('row4-son-main-left-row2-main').style.animation = 'lefteaseinAnimate 1s ease 1'
			},
			clickUl(index) {
				console.log(index)
				this.index = index
				this.subscript = index
				document.getElementById('row2-son-main-right-box').style.top =
					this.subscript * -420 + 'px'
			},
			move(key) {
				this.subscript = key
				document.getElementById('row2-son-main-right-box').style.top =
					this.subscript * -420 + 'px'
				this.index = key
			},
			goProductDetails() {
				this.$router.push({
					name: 'productDetails',
				})
			},
		},
	}
</script>

<style scoped>
	body,html{
		margin: 0;
		border: 0;
		padding: 0;
		width: 100%;
	}
	.main{
		width: 100%;
		max-width: 1920px;
		margin:  0 auto;
	}
	@keyframes lefteaseinAnimate {
		0% {
			transform: translateX(-2000px);
			opacity: 0;
		}

		100% {
			transform: translateX(0px);
			opacity: 1;
		}

	}

	.row {
		width: 1200px;
		margin: 0 auto;
	}

	.row1 {
		width: 100%;
		height: 680px;
		background-image: url(../../../static/img/fzlc.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
	}

	.row1>div {
		width: 100%;
		text-align: center;
	}

	.row1-son1 {
		position: absolute;
		top: 30%;
		/* padding-top: 216px; */
		font-size: 46px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #ffffff;
	}

	.row1-son2 {
		position: absolute;
		top: 45%;
		margin-top: 30px;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.row1-son3 {
		position: absolute;
		top: 55%;
		margin-top: 89px;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.row1-son3>div {
		width: 120px;
		height: 40px;
		background: rgba(66, 133, 244, 0);
		border: 1px solid #ffffff;
		text-align: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #ffffff;
		line-height: 40px;
		cursor: pointer;
	}

	.row2 {
		width: 100%;
	}

	.row2-son-title {
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #000000;
		padding-top: 158px;
		padding-bottom: 50px;
	}

	.row2-son-label>ul {
		list-style-type: none;
		display: flex;
	}

	.row2-son-label-ul-li {
		display: inline-block;
		margin-right: 50px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #1a1a1a;
		display: flex;
		justify-content: left;
		align-items: center;
		cursor: pointer;
	}

	.dot {
		width: 12px;
		height: 12px;
		background: #4285f4;
		border: 2px solid #cddffc;
		border-radius: 12px;
		margin-right: 5px;
	}

	.no-dot {
		width: 12px;
		height: 12px;
		background: #ffffff;
		border: 2px solid #ffffff;
		border-radius: 6px;
		margin-right: 5px;
	}

	.row2-son-main {
		width: 100%;
		display: flex;
		justify-content: space-around;
	}

	.row2-son-main-left {
		width: 4px;
		height: 420px;
		background: #f2f2f2;
	}

	.row2-son-main-left-div {
		width: 4px;
		height: 70px;
		background: #000000;
	}

	.row2-son-main-left-div1 {
		width: 4px;
		height: 70px;
		background: #f2f2f2;
	}

	.row2-son-main-right {
		width: 1096px;
		height: 420px;
		border: 1px solid #000000;
		overflow: hidden;
		position: relative;
	}

	#row2-son-main-right-box {
		width: 100%;
		position: absolute;
		top: 0;
		transition: all 0.5s;
	}

	.row2-son-main-right-box-son {
		width: 100%;
		height: 420px;
		display: flex;
		justify-content: space-between;
	}

	.row2-son-main-right-box-son-right {
		width: 500px;
		display: none;
	}

	.row2-son-main-right-box-son-right>img {
		width: 490px;
	}

	.row2-son-main-right-box-son-left {
		padding-left: 82px;
	}

	.row2-son-main-right-box-son-left-title {
		padding-top: 74px;
		font-size: 26px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #ffffff;
	}

	.row2-son-main-right-box-son-left-text {
		margin-top: 41px;
		width: 432px;
		height: 107px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 30px;
		/* overflow: hidden; */
		text-overflow: ellipsis;
	}

	.row2-son-main-right-box-son-left-btn {
		width: 144px;
		height: 36px;
		text-align: center;
		margin-top: 65px;
		line-height: 36px;
		background: rgba(255, 255, 255, 0);
		border: 1px solid #ffffff;
		cursor: pointer;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.row3 {
		width: 100%;
	}

	.row3-son-title {
		padding-top: 90px;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #191919;
	}

	.row3-son-main {
		padding-bottom: 198px;
	}

	.row3-son-main-box {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.row3-son-main-box-left {
		margin-top: 79px;
		width: 48%;
	}

	.row3-son-main-box-left>img {
		width: 420px;
		transition: all 0.6s;
	}

	.row3-son-main-box-left>img:hover {
		transform: scale(1.1);
	}

	.row3-son-main-box-right {
		margin-top: 79px;
		width: 48%;
	}

	.row3-son-main-box-right-rod {
		width: 4px;
		height: 32px;
		background: linear-gradient(0deg, #0a59dd 0%, #57adf8 100%);
		border-radius: 2px;
	}

	.row3-son-main-box-right-text {
		margin-left: 10px;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #1a1a1a;
	}

	.row3-son-main-box-right-title {
		display: flex;
		justify-content: left;
		align-items: center;
	}

	.row3-son-main-box-right-div2 {
		padding-top: 30px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ababab;
	}

	.row3-son-main-box-right-div3 {
		margin-top: 18px;
		font-size: 26px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #1a1a1a;
	}

	.row3-son-main-box-right-div4 {
		width: 100%;
	}

	.row3-son-main-box-right-div4>div {
		margin-top: 20px;
		width: 148px;
		height: 40px;
		background: #ffffff;
		border: 1px solid #3c444f;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #3c444f;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}

	.row4 {
		width: 100%;
		height: 977px;
		background: #0b0d23;
	}

	.row4-step {
		margin-top: 136px;
		width: 80%;
		height: 12px;
		background: #2e2a40;
		border-radius: 0px 8px 8px 0px;
		position: relative;
	}

	.row4-step-article {
		width: 29%;
		height: 12px;
		background: #5e6ace;
		border-radius: 0px 8px 8px 0px;
		transition: all 0.5s;
	}

	.row4-step-son {
		position: absolute;
		left: 28%;
		top: -15px;
		width: 20px;
		height: 20px;
		background: #5060ec;
		border: 10px solid #2e2a40;
		border-radius: 36px;
	}

	.row4-step-text {
		position: absolute;
		left: 26%;
		top: -60px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #9d9ea7;
		border: 1px solid #0b0d23;
		border-radius: 16px;
		width: 104px;
		height: 32px;
		text-align: center;
		line-height: 32px;
	}

	.row4-step-text1 {
		left: 39%;
	}

	.row4-step-text2 {
		left: 52%;
	}

	.row4-step-text3 {
		left: 66%;
	}

	.row4-step-text4 {
		left: 78%;
	}

	.row4-step-text5 {
		left: 91%;
	}

	.row4-step-son1 {
		left: 41%;
	}

	.row4-step-son2 {
		left: 54%;
	}

	.row4-step-son3 {
		left: 68%;
	}

	.row4-step-son4 {
		left: 80%;
	}

	.row4-step-son5 {
		left: 93%;
	}

	.row5 {
		width: 100%;
		padding-bottom: 55px;
		position: relative;
	}

	.row5>img {
		width: 100%;
		height: 453px;
		position: absolute;
		bottom: 0;
	}

	.row5-son-title {
		padding-top: 129px;
		padding-bottom: 63px;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #191919;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.row5-son-title>div:nth-child(2) {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #717984;
	}

	.row5-son-main {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.row5-son-main-box {
		/* padding: 0 26px; */
		width: 380px;
		height: 576px;
		background: #ffffff;
		box-shadow: 0px 0px 14px 0px #dce1ea;
		z-index: 1;
	}

	.row5-son-main-box-row1 {
		width: 100%;
		height: 73px;
		font-size: 26px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #ffffff;
		/* padding-top: 25px; */
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-image: url(../../../static/img/ab03.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.row5-son-main-box-row1>div:nth-child(2) {
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.row5-son-main-box-row2 {
		padding: 10px;
		width: 328px;
		height: 116px;
		background: #f8f8f9;
		margin: 0 auto;
		line-height: 30px;
	}

	.li-left {
		width: 12px;
		height: 12px;
		background: #5b98fd;
		border: 3px solid #d2e3fd;
		border-radius: 12px;
	}

	.li-left1 {
		width: 12px;
		height: 12px;
		background: #cbcbcb;
		border: 3px solid #eaeaea;
		border-radius: 12px;
	}

	.row5-son-main-box-row3-box {
		margin: 16px auto 0 auto;
		padding: 10px;
		width: 328px;
		background: linear-gradient(0deg, #ffffff 0%, #f8f8f9 100%);
	}

	.row5-son-main-box-row3-box>ul {
		list-style-type: none;
		padding-left: 0;
	}

	.row5-son-main-box-row3-box>ul>li {
		display: flex;
		justify-content: left;
		align-items: center;
		line-height: 30px;
	}

	.row5-son-main-box-row3-box>ul>li>div:nth-child(2) {
		margin-left: 5px;
	}

	.row6-son-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #191919;
		padding-top: 112px;
	}

	.row6-son-title>div:nth-child(2) {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #717984;
	}

	.row6-son-main {
		margin-top: 68px;
		width: 100%;
		height: 700px;
	}

	.row6-son-main-row1 {
		width: 100%;
		height: 220px;
		background-image: url(../../../static/img/cp/qyan-0.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: flex;
		justify-content: space-around;
	}

	.row6-son-main-row1>div {
		width: 30%;
		height: 100%;
	}

	.row6-son-main-row1>div>div:nth-child(1) {
		padding-top: 58px;
		width: 100%;
		text-align: center;
		font-size: 56px;
		font-family: Arial;
		font-weight: bold;
		color: #ffffff;
	}

	.row6-son-main-row1>div>div:nth-child(2) {
		width: 100%;
		text-align: center;
		padding-top: 34px;
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.row6-son-main-row2 {
		width: 100%;
		height: 480px;
		display: flex;
		justify-content: left;
	}

	.row6-son-main-row2-box {
		width: 25%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.box-top {
		width: 100%;
		height: 70%;
		background-color: #535353;
	}

	.box-bottom {
		width: 100%;
		height: 29.8%;
		background-color: #555555;
	}

	.complete-box {
		width: 100%;
		height: 100%;
		background: #383b3c;
	}

	.complete-box>div:nth-child(1) {
		padding-top: 103px;
		width: 100%;
		text-align: center;
		font-size: 38px;
		font-family: PingFang SC;
		font-weight: bold;
		font-style: italic;
		color: #ffffff;
	}

	.complete-box>div:nth-child(2) {
		padding-top: 10px;
		width: 100%;
		text-align: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.complete-box-son3 {
		margin-top: 172px;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.complete-box-son3>div {
		width: 48px;
		height: 48px;
		border: 1px solid #FFFFFF;
		border-radius: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.complete-box-son3>div>img {
		width: 40px;
	}

	.row4-son {
		padding-top: 104px;
	}

	.row4-son-main {
		width: 1192px;
		height: 600px;
		background: #000000;
		display: flex;
		justify-content: center;
	}

	.row4-son-main>div {
		width: 41%;
		height: 100%;
		position: relative;
	}

	.row6-son-btnBox {
		margin-top: 60px;
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 80px;
	}

	.row6-son-btn {
		width: 148px;
		height: 40px;
		background: rgba(255, 255, 255, 0);
		border: 1px solid #000000;
		text-align: center;
		line-height: 40px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #3c444f;
		cursor: pointer;
	}

	.row4-son-main-left {
		width: 100%;
	}

	.row4-son-main-left-title {
		width: 4px;
		height: 32px;
		background: linear-gradient(0deg, #0a59dd 0%, #57adf8 100%);
		border-radius: 2px;
		margin-right: 20px;
	}

	.row4-son-main-left-text {
		font-size: 32px;
		font-family: Noto Sans S Chinese;
		font-weight: 400;
		color: #ffffff;
		line-height: 28px;
		animation-name: fadeInLeftSmall;
		height: 32px;
	}

	.row4-son-main-left-text>span {
		animation: lefteaseinAnimate 1s ease 1;
		animation-fill-mode: forwards;
	}

	#row4-son-main-left-row2-main {
		animation: lefteaseinAnimate 1s ease 1;
		animation-fill-mode: forwards;
	}

	.row4-son-main-left-row1 {
		margin-top: 91px;
		width: 100%;
		display: flex;
		align-items: center;
		overflow: hidden;
	}

	.row4-son-main-left-row2 {
		margin-top: 28px;
		width: 228px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 26px;
		transition: all 0.6s;
	}

	.row4-son-main-left-btn {
		margin-top: 239px;
		width: 148px;
		height: 40px;
		background: rgba(255, 255, 255, 0);
		border: 1px solid #ffffff;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		position: absolute;
		bottom: 70px;
	}

	.row4-son-main-right {
		position: relative;
	}

	.row4-son-main-right-img {
		margin-top: 92px;
		width: 440px;
		height: 322px;
		z-index: 9;
	}

	.row4-son-main-right-img>img {
		width: 440px;
		position: absolute;
		z-index: 9;
		transition: all 0.6s;
	}

	.row4-son-main-right-img>img:hover {
		transform: scale(1.1);
	}

	.row4-son-main-right-box {
		position: absolute;
		top: 110px;
		left: 110px;
		width: 376px;
		height: 288px;
		background: #171717;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 1s;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	.backgroudImg {
		background-image: url(../../../static/img/cp/qyal-4.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.backgroudImg1 {
		background-image: url(../../../static/img/cp/qyal-2.jpg);
		background-repeat: no-repeat;
		background-position: bottom;
	}

	.backgroudImg2 {
		background-image: url(../../../static/img/cp/qyal-1.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.backgroudImg3 {
		background-image: url(../../../static/img/cp/qyal-3.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.backgroudImg4 {
		background-image: url(../../../static/img/cp/qyal-5.jpg);
		background-repeat: no-repeat;
	}

	.backgroudImg5 {
		background-image: url(../../../static/img/cp/qyal-6.jpg);
		background-repeat: no-repeat;
		background-position: bottom;
	}

	.backgroudImg6 {
		background-image: url(../../../static/img/cp/qyal-7.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.color-gray {
		color: #abababs;
	}
</style>
