import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home";
import plan from "@/views/Plan/plan.vue";
import Details from "@/views/Details/details.vue";
import Product from "@/views/Product/product.vue";
import moralExample from "@/views/moralExample/moralExample.vue";
import Information from "@/views/Information/information.vue";
import informationDetails from "@/views/Information/informationDetails.vue";
import recruitment from "@/views/Recruitment/recruitment.vue";
import PlanDetails from "@/views/Plan/plahDetails.vue";
import moralExampleDetails from "@/views/moralExample/moralExampleDetails.vue";
import Jobs from "@/views/Recruitment/Jobs.vue";
import productDetails from "@/views/Product/productDetails.vue";
Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/Home",
      name: "Home",
      component: Home,
    },
    {
      path: "/plan",
      name: "plan",
      component: plan,
    },
    {
      path: "/Details",
      name: "Details",
      component: Details,
    },
    {
      path: "/Product",
      name: "Product",
      component: Product,
    },
    {
      path: "/moralExample",
      name: "moralExample",
      component: moralExample,
    },
    {
      path: "/Information",
      name: "Information",
      component: Information,
    },
    {
      path: "/informationDetails",
      name: "informationDetails",
      component: informationDetails,
    },
    {
      path: "/recruitment",
      name: "recruitment",
      component: recruitment,
    },
    {
      path: "/PlanDetails",
      name: "PlanDetails",
      component: PlanDetails,
    },
    {
      path: "/moralExampleDetails",
      name: "moralExampleDetails",
      component: moralExampleDetails,
    },
    {
      path: "/Jobs",
      name: "Jobs",
      component: Jobs,
    },
    {
      path: "/productDetails",
      name: "productDetails",
      component: productDetails,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});
