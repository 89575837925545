<template>
  <div class="main">
    <div class="row1">
      <img style="width: 100%;" src="../../../static/img/banner/gydt.jpg" />
      <div>
        <p>
          帝天,&nbsp;重视产品和服务的商家服务公司
        </p>
      </div>
    </div>
    <div class="row2">
      <div class="row">
        <div class="row2-title">
          关于帝天
        </div>
        <div id="row2-content" ref="content">
          互联网客群、提高经营效率，全面助力商家成功，并战略为商家引流宣传拓展。
          帝天公司致力于成为中国优秀的计算机软件研发团队，核心团队成员均来至于业内知名的互联网公司，
          凭借专业的技术能力，丰富的技术经验，后助执着敬业的精神以及优质的服务，
          并以不懈的努力取得了福建省和国家高新企业的认证，并且是IBM,华为云，
          京东云的合作伙伴,还是福建广播电视供应商，我们的企业文化《传承》，
          把我们经验分享下去，避免后来人重复走弯路。
        </div>
        <div class="row2-icon">
          <div class="row2-icon-son">
            <div class="row2-icon-son-row1">
              <span>1</span>
              <span>个使命</span>
            </div>
            <div class="row2-icon-son-row2">
              赋能企业数字化转型
            </div>
          </div>
          <div class="row2-icon-son">
            <div class="row2-icon-son-row1">
              <span>20</span><span>年专注</span>
            </div>
            <div class="row2-icon-son-row2">
              深耕高端OA与知识管理
            </div>
          </div>
          <div class="row2-icon-son">
            <div class="row2-icon-son-row1">
              <span>150</span><span>家服务机构</span>
            </div>
            <div class="row2-icon-son-row2">
              全国提供专业服务
            </div>
          </div>
          <!-- <div class="row2-icon-son">
						<div class="row2-icon-son-row1">
							<span>1</span><span>个使命</span>
						</div>
						<div class="row2-icon-son-row2">
							赋能企业数字化转型
						</div>
					</div>
					<div class="row2-icon-son">
						<div class="row2-icon-son-row1">
							<span>20</span><span>年专注</span>
						</div>
						<div class="row2-icon-son-row2">
							深耕高端OA与知识管理
						</div>
					</div>
					<div class="row2-icon-son">
						<div class="row2-icon-son-row1">
							<span id="num">150</span><span>家服务机构</span>
						</div>
						<div class="row2-icon-son-row2">
							全国提供专业服务
						</div>
					</div> -->
        </div>
      </div>
    </div>
    <div class="row3">
      <div class="row row3-son">
        <div class="row3-son-title">
          发展历程
        </div>
        <div class="row3-son-main">
          <div class="row3-son-main-left" @click="mobileContent('left')">
          </div>
          <div class="row3-son-main-content" ref="content">
            <div class="row3-son-main-content-box" ref="contentBox">
              <div v-for="(item,key) in infoArr" :key="key" style="width: 626px;height: 220px;display: flex;justify-content: space-between;">
                <div class="row3-son-main-content-left">
                  <img src="../../../static/img/fzlc_1.jpg" />
                </div>
                <div class="row3-son-main-content-right">
                  <ul>
                    <li v-for="(it,i) in  item.info">
                      <div class="red-square"></div>
                      <div>
                        {{ it }}
                      </div>
                    </li>
                  </ul>
                  <div class="row3-son-main-content-right-time">
                    {{ item.years }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row3-son-main-right" @click="mobileContent('right')">
          </div>
        </div>
        <div class="row3-son-info">
          <div class="row3-son-info-box">
            <div :class="index == 0 ?  'row3-son-info-time' : 'row3-son-info-time size-color'">
              2015
            </div>
            <div :class="index == 0 ?  'row3-son-info-name' : 'row3-son-info-name size-color'">
              福州帝天信息科技有限公司成立
            </div>
            <div class="row3-son-info-icon">
              <img src="../../../static/img/td2.png" />
            </div>
          </div>
          <div class="row3-son-info-box1">
            <div :class="index == 1 ?  'row3-son-info-time' : 'row3-son-info-time size-color'">
              2016
            </div>
            <div :class="index == 1 ?  'row3-son-info-name' : 'row3-son-info-name size-color'">
              福州帝天信息科技有限公司成立
            </div>
            <div class="row3-son-info-icon">
              <img src="../../../static/img/td2.png" />
            </div>
          </div>
          <div class="row3-son-info-box2">
            <div :class="index == 2 ?  'row3-son-info-time' : 'row3-son-info-time size-color'">
              2017
            </div>
            <div :class="index == 2 ?  'row3-son-info-name' : 'row3-son-info-name size-color'">
              福州帝天信息科技有限公司成立
            </div>
            <div class="row3-son-info-icon">
              <img src="../../../static/img/td2.png" />
            </div>
          </div>
          <div class="row3-son-info-box3">
            <div :class="index == 3 ?  'row3-son-info-time' : 'row3-son-info-time size-color'">
              2018
            </div>
            <div :class="index == 3 ?  'row3-son-info-name' : 'row3-son-info-name size-color'">
              福州帝天信息科技有限公司成立
            </div>
            <div class="row3-son-info-icon">
              <img src="../../../static/img/td2.png" />
            </div>
          </div>
          <div class="row3-son-info-box4">
            <div :class="index == 4 ?  'row3-son-info-time' : 'row3-son-info-time size-color'">
              2019
            </div>
            <div :class="index == 4 ?  'row3-son-info-name' : 'row3-son-info-name size-color'">
              福州帝天信息科技有限公司成立
            </div>
            <div class="row3-son-info-icon">
              <img src="../../../static/img/td2.png" />
            </div>
          </div>
          <div class="row3-son-info-box5">
            <div :class="index == 5 ?  'row3-son-info-time' : 'row3-son-info-time size-color'">
              2020
            </div>
            <div :class="index == 5 ?  'row3-son-info-name' : 'row3-son-info-name size-color'">
              福州帝天信息科技有限公司成立
            </div>
            <div class="row3-son-info-icon">
              <img src="../../../static/img/td2.png" />
            </div>
          </div>
          <div class="row3-son-info-box6">
            <div :class="index == 6 ?  'row3-son-info-time' : 'row3-son-info-time size-color'">
              2021
            </div>
            <div :class="index == 6 ?  'row3-son-info-name' : 'row3-son-info-name size-color'">
              福州帝天信息科技有限公司成立
            </div>
            <div class="row3-son-info-icon">
              <img src="../../../static/img/td2.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row4">
      合作企业及客户
    </div>
    <div class="row5">
      <div class="row row5-son">
        <img src="../../../static/img/hbkh/定制巴士.png" height="48" width="238">
        <img src="../../../static/img/hbkh/IBM.png" height="48" width="238">
        <img src="../../../static/img/hbkh/南威.png" height="48" width="238">
        <img src="../../../static/img/hbkh/顺丰速运.png" height="48" width="238">
        <img src="../../../static/img/hbkh/网龙网络.png" height="48" width="238">
        <img src="../../../static/img/hbkh/伟思国瑞.png" height="48" width="238">
        <img src="../../../static/img/hbkh/星网锐捷.png" height="48" width="238">
        <img src="../../../static/img/hbkh/易联众.png" height="48" width="238">
        <img src="../../../static/img/hbkh/知鱼.png" height="48" width="238">
        <img src="../../../static/img/hbkh/北京高信达通信.png" height="48" width="238">
        <img src="../../../static/img/hbkh/福建华博教育.png" height="48" width="238">
        <img src="../../../static/img/hbkh/福州日报社.png" height="48" width="238">
        <img src="../../../static/img/hbkh/中庚科技.png" height="48" width="238">
        <img src="../../../static/img/hbkh/中锐网络.png" height="48" width="238">
        <img src="../../../static/img/hbkh/yd.jpg" height="48" width="238">
        <img src="../../../static/img/hbkh/lt.jpg" height="48" width="238">
        <a href="https://www.fzyntgd.com/" target="_black">
          <img src="../../../static/img/hbkh/ynt.png" height="48" width="238">
        </a>
      </div>
    </div>
    <div class="row6">
      <div class="row">
        <div class="row6-son">
          <div class="row6-son-title">
            真诚服务每一位客户·尽力完成每一件产品
          </div>
          <div class="row6-son-content">
            帝天公司将秉承 "润物细无声" 的服务理念，继续为更多的企业提供更优质、完善的技术服务。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      index: 0,
      infoArr: [{
          years: '2015',
          info: [
            '福州帝天信息科技有限公司于2015年成立。',
          ]
        },
        {
          years: '2016',
          info: [
            // '福州帝天信息科技有限公司于2015年成立。',
            // '福州帝天信息科技有限公司于2015年成立。'
          ]
        },
        {
          years: '2017',
          info: [
            // '福州帝天信息科技有限公司于2015年成立。',
            // '福州帝天信息科技有限公司于2015年成立。'
          ]
        },
        {
          years: '2018',
          info: [
            // '福州帝天信息科技有限公司于2015年成立。',
            // '福州帝天信息科技有限公司于2015年成立。'
          ]
        },
        {
          years: '2019',
          info: [
            // '福州帝天信息科技有限公司于2015年成立。',
            // '福州帝天信息科技有限公司于2015年成立。'
          ]
        },
        {
          years: '2020',
          info: [
            // '福州帝天信息科技有限公司于2015年成立。',
            // '福州帝天信息科技有限公司于2015年成立。'
          ]
        },
        {
          years: '2021',
          info: [
            // '福州帝天信息科技有限公司于2015年成立。',
            // '福州帝天信息科技有限公司于2015年成立。'
          ]
        }
      ]
    }
  },
  created() {

  },
  methods: {
    mobileContent(mobileContent) {
      if (mobileContent == 'left') {
        if (this.index > 0) {
          this.index = this.index - 1
          this.$refs.contentBox.style.left = this.index * -626 + 'px'
        }
      }
      if (mobileContent == 'right') {
        if (this.index < 6) {
          this.index = this.index + 1
          this.$refs.contentBox.style.left = this.index * -626 + 'px'
        }
      }

    }
  }
}

</script>
<style scoped>
body,
html {
  margin: 0;
  border: 0;
  padding: 0;
  width: 100%;
}

.main {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.row {
  width: 1200px;
  margin: 0 auto;
}

.row1 {
  width: 100%;
  position: relative;
}

.row1>div {
  position: absolute;
  top: 30%;
  z-index: 9;
  color: #ffffff;
  width: 100%;
  text-align: center;
}

.row1>div>P:nth-child(1) {
  font-size: 36px;
}

.row1>div>P:nth-child(2) {
  font-size: 20px;
}

.row2 {
  width: 100%;
  padding-bottom: 60px;
}

.row2-title {
  padding-top: 68px;
  padding-bottom: 50px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #20242B;
}

#row2-content {
  margin: 0 auto;
  width: 850px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #737C8B;
  line-height: 42px;
  opacity: 1;
  /* display: none; */
}

/*页面加载文字移入动画*/
@keyframes lefteaseinAnimate {
  0% {
    transform: translateY(-2000px);
    transform: rotate(45deg);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    transform: rotate(0deg);
    opacity: 1;
  }

}

.row2-icon {
  margin: 0 auto;
  padding-top: 60px;
  width: 850px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row2-icon>div {
  width: 26%;
  height: 100px;
  margin-bottom: 50px;
}

.row3 {
  width: 100%;
  height: 720px;
  background-image: url(../../../static/img/fzlc.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.row4 {
  width: 100%;
  text-align: center;
  line-height: 158px;
  height: 158px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #20242B;
}

.row5 {
  width: 100%;
}

.row5-son {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row5-son>img {
  margin:0 5px 60px 5px;
}

.row6 {
  width: 100%;
  margin-top: 30px;
  height: 240px;
  background-color: #F7F8FB;
}

.row6-son {
  width: 100%;
  height: 240px;
}

.row6-son-title {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #20242B;
  padding-top: 85px;
}

.row6-son-content {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #737C8B;
}

.row2-icon-son-row1>span:nth-child(1) {
  font-size: 52px;
  font-family: Noto Sans S Chinese;
  font-weight: 500;
  color: #363F4E;
}

.row2-icon-son-row1>span:nth-child(2) {
  margin-left: 5px;
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 500;
  color: #363F4E;
}

.row2-icon-son-row2 {
  margin-top: 12px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #737C8B;
}

.row3-son-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row3-son-title {
  padding-top: 48px;
  padding-bottom: 27px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}

.row3-son-main-content {
  width: 616px;
  height: 220px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.row3-son-main-content-left {
  width: 59%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row3-son-main-content-left>img {
  width: 300px;
}

.row3-son-main-content-right {
  width: 50%;
  height: 100%;
  position: relative;
}

.red-square {
  width: 6px;
  height: 6px;
  background: #F0465C;
  margin-right: 5px;
  margin-left: 5px;
}

.row3-son-main-content-right>ul {
  margin-top: 22px;
  list-style-type: none;
  padding-left: 0;
}

.row3-son-main-content-right>ul>li {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #20242B;
  height: 30px;
}

.row3-son-main-left {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  border-left: 1px solid #586272;
  border-bottom: 1px solid #586272;
}

.row3-son-main-right {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  border-right: 1px solid #586272;
  border-top: 1px solid #586272;
}

.row3-son-main-content-right-time {
  position: absolute;
  right: 56px;
  bottom: 7px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #D7D9DC;
}

.row3-son-info {
  margin-top: 80px;
  width: 100%;
  height: 300px;
  position: relative;
}

.row3-son-info-time {
  width: 170px;
  text-align: center;
  font-size: 32px;
  font-family: Arial;
  font-weight: bold;
  color: #FFFFFF;
}

.row3-son-info-name {
  margin-top: 17px;
  width: 170px;
  text-align: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}

.row3-son-info-icon {
  margin-top: 11px;
  width: 170px;
  display: flex;
  justify-content: center;
}

.row3-son-info-icon>img {
  width: 16px;
}

.row3-son-info-box {
  position: absolute;
  top: 10px;
  left: 235px;
}

.row3-son-info-box1 {
  position: absolute;
  top: -6px;
  left: 466px;
}

.row3-son-info-box2 {
  position: absolute;
  top: 15px;
  left: 770px;
}

.row3-son-info-box3 {
  position: absolute;
  top: 118px;
  left: 31px;
}

.row3-son-info-box4 {
  position: absolute;
  top: 110px;
  left: 350px;
}

.row3-son-info-box5 {
  position: absolute;
  top: 118px;
  left: 620px;
}

.row3-son-info-box6 {
  position: absolute;
  top: 110px;
  right: 60px;
}

.size-color {
  color: #FFFFFF;
  opacity: 0.6;
}

.row3-son-main-content-box {
  position: absolute;
  top: 0;
  left: 0;
  transition: all .5s;
  display: flex;
  justify-content: left;
}

.row3-son-main-content-box>div {
  display: inline-block;
}

</style>
