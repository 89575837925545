<template>
	<div class="main">
		<div class="row1">
			<div class="row1-son1">多领域商城系统</div>
			<div class="row1-son2">新一代电商解决方案，打造您的“私域流量”人专属商城</div>
			<div class="row1-son3">
				<div @click="goYuyue()">
					预约交流
				</div>
			</div>
		</div>
		<div class="row2">
			<div class="row row2-son">
				<div class="row2-son-row1">
					<div class="row2-son-row1-box1">
						<div>
							产品亮点
						</div>
						<div>
							为数字化转型增效降本
						</div>
					</div>
					<div class="row2-son-row1-box2">
						<div>
							<img src="../../../static/img/ld.png" />
						</div>
						<div class="row2-son-row1-box2-title">
							提高企业效能
						</div>
						<div class="row2-son-row1-box2-text">
							打通新旧业务的数据壁垒，新旧组织间的协作壁垒，解决了求稳与求变的矛盾，同时减少生态人员、业务间的沟通成本，提升企业整体运营和创新效能。
						</div>
					</div>
				</div>
				<div class="row2-son-row2">
					<div class="row2-son-row2-box" v-for="(item,index) in 3" :key="index">
						<div>
							<img src="../../../static/img/ld.png" />
						</div>
						<div class="row2-son-row1-box2-title">
							提高企业效能
						</div>
						<div class="row2-son-row1-box2-text">
							打通新旧业务的数据壁垒，新旧组织间的协作壁垒，解决了求稳与求变的矛盾，同时减少生态人员、业务间的沟通成本，提升企业整体运营和创新效能。
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="row3">
			<div class="row row3-son">
				<div class="row3-son-title">
					产品特性
				</div>
				<div class="row3-son-main" v-for="(item,index) in imgArr" :key="index">
					<div class="row3-son-main-left" v-if="index%2 != 0">
						<img :src="item.img" />
					</div>
					<div class="row3-son-main-right">
						<div class="row3-son-main-right-title">
							<div class=""></div>
							<div>
								{{item.title}}
							</div>
						</div>
						<div class="row3-son-main-right-text">
							{{item.content}}
						</div>
						<div class="row3-son-main-right-btn" @click="goYuyue">
							预约交流
						</div>
					</div>
					<div class="row3-son-main-left" v-if="index%2 == 0">
						<img :src="item.img" />
					</div>
				</div>

			</div>
		</div>
		<div class="row4">
			<div class="row row4-son">
				<div class="row4-son-title">
					合作案例
				</div>
				<div class="row4-son-text">
					包括恒大集团、民生银行、越秀地产、京博集团、林氏木业等行业标杆企业，
					选择蓝凌MK-PaaS平台，推动企业数字化平台更加完善，赋能转型 升级与创新发展。
				</div>
				<div class="row4-son-main">
					<div class="row4-son-main-div">
						<div class="row4-son-main-left">
							<div id="container">
								<div class="row4-son-main-left-son1" v-for="item in 3">
									<img src="../../../static/img/al.jpg" />
								</div>
							</div>
							<div class="row4-son-main-left-son2">
								<div>1</div>
								<div :class="index == key ? 'vertical vertical1' : 'vertical'"
									@mouseover="caseSwitch(key)" v-for="(item,key) in 3"></div>
								<div>3</div>
							</div>
						</div>
						<div class="row4-son-main-right">
							<div class="row4-son-main-right-title">
								<div class="row4-son-main-right-title-son1">

								</div>
								<div class="row4-son-main-right-title-son2">
									糖果蛋糕
								</div>
							</div>
							<div class="row4-son-main-right-main">
								丰富的门户样式模版和内容模版，
								同一数据源可以选择不同呈现方式，
								满足多行业、多场景门户需求，
								帮助集团型企业打造专属高效办公门户。
							</div>
							<div class="row4-son-right-btn">
								查看详情
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				index: 0,
				imgArr: [{
						title: '统一门户',
						content: '所见即所得的门户配置，实时预览可选择不同权限用户、不同数据来源，大大提高门户从配置到正式发布的效率。丰富的门户样式模版和内容模版， 同一数据源可以选择不同呈现方式， 满足多行业、 多场景门户需求， 帮助集团型企业打造专属高效办公门户.',
						img: require('../../../static/img/特色-1零距离.png')
					},
					{
						title: '统一门户',
						content: '所见即所得的门户配置，实时预览可选择不同权限用户、不同数据来源，大大提高门户从配置到正式发布的效率。丰富的门户样式模版和内容模版， 同一数据源可以选择不同呈现方式， 满足多行业、 多场景门户需求， 帮助集团型企业打造专属高效办公门户.',
						img: require('../../../static/img/特色-超低成本.png')
					},
					{
						title: '统一门户',
						content: '所见即所得的门户配置，实时预览可选择不同权限用户、不同数据来源，大大提高门户从配置到正式发布的效率。丰富的门户样式模版和内容模版， 同一数据源可以选择不同呈现方式， 满足多行业、 多场景门户需求， 帮助集团型企业打造专属高效办公门户.',
						img: require('../../../static/img/特色-3高度互动.png')
					},
					{
						title: '统一门户',
						content: '所见即所得的门户配置，实时预览可选择不同权限用户、不同数据来源，大大提高门户从配置到正式发布的效率。丰富的门户样式模版和内容模版， 同一数据源可以选择不同呈现方式， 满足多行业、 多场景门户需求， 帮助集团型企业打造专属高效办公门户.',
						img: require('../../../static/img/特色-4私有化.png')
					},
					{
						title: '统一门户',
						content: '所见即所得的门户配置，实时预览可选择不同权限用户、不同数据来源，大大提高门户从配置到正式发布的效率。丰富的门户样式模版和内容模版， 同一数据源可以选择不同呈现方式， 满足多行业、 多场景门户需求， 帮助集团型企业打造专属高效办公门户.',
						img: require('../../../static/img/特色-5及时交付.png')
					},
					{
						title: '统一门户',
						content: '所见即所得的门户配置，实时预览可选择不同权限用户、不同数据来源，大大提高门户从配置到正式发布的效率。丰富的门户样式模版和内容模版， 同一数据源可以选择不同呈现方式， 满足多行业、 多场景门户需求， 帮助集团型企业打造专属高效办公门户.',
						img: require('../../../static/img/特色-6牢固关系.png')
					}
				]
			}
		},
		methods: {
			//点击预约跳转到预约表单位置
			goYuyue() {
				window.scrollTo(0, document.documentElement.scrollHeight - document.documentElement.clientHeight);
			},
			caseSwitch(key) {
				console.log(key);
				this.index = key;
				document.getElementById("container").style.top =
					this.index * -440 + "px";
			}
		}
	}
</script>

<style scoped>
	body,html{
		margin: 0;
		border: 0;
		padding: 0;
		width: 100%;
	}
	.main{
		width: 100%;
		max-width: 1920px;
		margin:  0 auto;
	}
	.row {
		width: 1200px;
		margin: 0 auto;
	}

	.row1 {
		width: 100%;
		height: 680px;
		background-image: url(../../../static/img/bj-yy.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.row1>div {
		width: 100%;
		text-align: center;
	}

	.row1-son1 {
		padding-top: 216px;
		font-size: 46px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #FFFFFF;
	}

	.row1-son2 {
		margin-top: 30px;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.row1-son3 {
		margin-top: 89px;
		width: 100%;
		display: flex;
		justify-content: center;

	}

	.row1-son3>div {
		width: 120px;
		height: 40px;
		background: rgba(66, 133, 244, 0);
		border: 1px solid #FFFFFF;
		text-align: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 40px;
		cursor: pointer;
	}

	.row2-son-row1 {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	.row2-son-row1-box2 {
		margin-top: 102px;
		width: 360px;
		height: 260px;
		background: #FFFFFF;
		box-shadow: 0px 1px 20px 0px rgba(115, 124, 139, 0.2);
		border-radius: 20px;
		padding: 0 20px;
	}

	.row2-son-row1-box1 {
		padding-top: 132px;
		font-size: 48px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #000000;
	}

	.row2-son-row1-box1>div:nth-child(2) {
		padding-top: 26px;
		font-size: 34px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #000000;
	}

	.row2-son-row1-box2>div:nth-child(1) {
		margin-top: 20px;
	}

	.row2-son-row1-box2>div:nth-child(1)>img {
		width: 36px;
	}

	.row2-son-row1-box2-title {
		margin-top: 24px;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #000000;
	}

	.row2-son-row1-box2-text {
		margin-top: 28px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ABABAB;
		line-height: 22px;
	}

	.row2-son-row2 {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.row2-son-row2-box {
		width: 360px;
		height: 260px;
		padding: 0 20px;
	}

	.row2-son-row2-box>div:nth-child(1) {
		margin-top: 20px;
	}

	.row2-son-row2-box:hover {
		box-shadow: 0px 1px 20px 0px rgb(115 124 139 / 20%);
		border-radius: 20px;
	}

	.row3-son-title {
		margin-top: 120px;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #191919;
	}

	.row3-son-main {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 100px;
	}

	.row3-son-main-left>img {
		width: 520px;
		transition: all 0.6s;
	}

	.row3-son-main-left>img:hover {
		transform: scale(1.1);
	}

	.row3-son-main-right {
		width: 43%;
	}

	.row3-son-main-right-title {
		margin-top: 35px;
		display: flex;
		justify-content: left;
		align-items: center;
	}

	.row3-son-main-right-title>div:nth-child(1) {
		width: 4px;
		height: 32px;
		background: linear-gradient(0deg, #0A59DD 0%, #57ADF8 100%);
		border-radius: 2px;
	}

	.row3-son-main-right-title>div:nth-child(2) {
		margin-left: 10px;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #000000;
	}

	.row3-son-main-right-text {
		width: 430px;
		margin-top: 41px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ABABAB;
		line-height: 26px;
	}

	.row3-son-main-right-btn {
		margin-top: 41px;
		width: 148px;
		height: 40px;
		background: #4285F4;
		border-radius: 20px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}

	.row4 {
		width: 100%;
	}

	.row4-son {}

	.row4-son-title {
		padding-top: 150px;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #191919;
	}

	.row4-son-text {
		padding-top: 30px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #191919;
		padding-bottom: 92px;
	}

	.row4-son-main {
		width: 100%;
	}

	.row4-son-main-left {
		display: flex;
		align-items: center;
		height: 440px;
		overflow: hidden;
		position: relative;
	}

	.row4-son-main-left-son2 {
		margin-left: 560px;
	}

	.row4-son-main-left-son2>div {
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #000000;
		margin-bottom: 4px;
	}

	.vertical {
		width: 4px;
		height: 124px;
		background: #F2F2F2;
		margin: 0 auto;
	}

	.vertical1 {
		background: #000000;
	}

	.row4-son-main-left-son1 {
		height: 440px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.row4-son-main-left-son1>img {
		width: 464px;
	}

	#container {
		position: absolute;
		top: 0;
		transition: all 0.5s;
	}

	.row4-son-main-right {
		width: 40%;
		height: 100%;
	}

	.row4-son-main-div {
		height: 440px;
		overflow: hidden;
		position: relative;
		display: flex;
		justify-content: space-between;
		padding-bottom: 92px;
	}

	.row4-son-main-right-title {
		margin-top: 90px;
		width: 100%;
		display: flex;
		justify-content: left;
		align-items: center;
	}

	.row4-son-main-right-title-son1 {
		width: 4px;
		height: 32px;
		background: linear-gradient(0deg, #0A59DD 0%, #57ADF8 100%);
		border-radius: 4px;
	}

	.row4-son-main-right-title-son2 {
		height: 42px;
		line-height: 42px;
		margin-left: 10px;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #000000;
	}

	.row4-son-main-right-main {
		margin-top: 41px;
		width: 428px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ABABAB;
		line-height: 26px;
	}

	.row4-son-right-btn {
		width: 148px;
		height: 40px;
		background: #4285F4;
		border-radius: 20px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		margin-top: 50px;
		cursor: pointer;
	}
</style>
