<template>
	<div class="main">
		<div class="row2">
			<el-carousel height="480px">
				<el-carousel-item v-for="(item, key) in bannerArr" :key="key">
					<div class="banner-box" :style="'background-image: url(' + item.picture_url + ');'">
						<div class="row">
							<div class="row2-div-son1">{{ item.title }}</div>
							<div class="row2-div-son2">{{ item.introduce }}</div>
							<div class="row2-div-son3">
								商品展示丨订单管理丨客户引流丨物流互动丨客户管理丨提现快捷
							</div>
							<div class="row2-div-son4">
								<div class="row2-div-son4-btn">点击了解详情</div>
							</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="row3">
			<div class="row3-son1">我们的产品和服务</div>
			<div class="row3-son2">
				<i @click="left(1)" class="home-tabs-btn home-tabs-btn-left"></i>
				<div>
					<div id="row3-son2-son-main">
						<div class="row3-son2-box" @click="goBox(key)" v-for="(item, key) in  smailArr" :key="key">
							<div class="row3-son2-img">
								<img :src="index == key ? item.imgClick : item.img" />
							</div>
							<div :class="index == key ? 'row3-son2-text' : 'row3-son2-text color-black'">{{ item.name }}
							</div>
						</div>
					</div>
				</div>
				<i @click="left(2)" class="home-tabs-btn home-tabs-btn-right"></i>
			</div>
		</div>
		<div class="row4">
			<div class="row row4-son">
				<div style="display: flex; justify-content: space-between; width: 100%;" v-for="(item, key) in smailArr"
					:key="key" v-show="index == key">
					<div class="row4-son-box1">
						<div class="row4-son-box1-row1" ref='boxtIitle'>
							{{ item.title }}
						</div>
						<div class="row4-son-box1-row2">
							{{ item.main }}
						</div>
						<div class="row4-son-box1-row3">
							<a>了解详情 ></a>
						</div>
						<div class="row4-son-box1-row4">
							<div class="row4-son-box1-row4-box" v-for="(it, ind) in item.label" :key="ind">
								{{ it }}
							</div>
						</div>
						<div class="row4-son-box1-row4-btn" @click="goYuyue()">预约交流</div>
					</div>
					<div class="row4-son-box2">
						<div class="row4-son-box2-img">
							<img :src="item.icon" />
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="row5">
			<div class="row5-son1">解决方案</div>
			<div class="row5-son2">
				<div class="row">
					<div class="row5-son2-left">
						<div style="padding-top: 45px"></div>
						<div @mouseover="Click(0)" :class="classFlag == 0 ? 'row5-son2-left-color' : ''">
							智慧政务
						</div>

						<div @mouseover="Click(1)" :class="classFlag == 1 ? 'row5-son2-left-color' : ''">
							智慧城市
						</div>

						<div @mouseover="Click(2)" :class="classFlag == 2 ? 'row5-son2-left-color' : ''">
							智慧园区
						</div>

						<div @mouseover="Click(3)" :class="classFlag == 3 ? 'row5-son2-left-color' : ''">
							智慧党建
						</div>

						<div @mouseover="Click(4)" :class="classFlag == 4 ? 'row5-son2-left-color' : ''">
							智慧医疗
						</div>
						<div @mouseover="Click(5)" :class="classFlag == 5 ? 'row5-son2-left-color' : ''">
							智慧零售
						</div>
						<div @mouseover="Click(6)" :class="classFlag == 6 ? 'row5-son2-left-color' : ''">
							融媒体平台
						</div>
						<div @click="goMoralExample()">查看更多方案&nbsp;></div>
					</div>
					<div class="row5-son2-right">
						<div v-show="classFlag == index" v-for="(item, index) in planArr" :key="index">
							<div class="row5-son2-right-text1">{{ item.title }}</div>
							<div class="row5-son2-right-text2">
								{{ item.main }}
							</div>
							<div class="row5-son2-right-btn" @click="goPlan">了解详情</div>
							<div class="row5-son2-right-text4" v-if="item.icon.length > 0">相关案例</div>
							<div class="row5-son2-right-img">
								<img v-for="(it, key) in item.icon" :src="it" :key="key" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row6">
			<div class="row">
				<div class="row6-title">技术+经验，一家专业的商家服务公司</div>
				<div class="row6-main">
					<div class="row6-main-row1">
						<div @click="Switch(1)" @mouseover="Move(1)" :class="tabFlag ? 'row6-main-row1-son1 tab' : 'row6-main-row1-son1'
							">
							我们的客户
						</div>
						<div @click="Switch(2)" @mouseover="Move(2)" :class="tabFlag ? 'row6-main-row1-son2' : 'row6-main-row1-son2 tab'
							">
							合作伙伴
						</div>
						<!-- <div class="row6-main-row1-right">行业实践 ></div> -->
					</div>
					<div class="row6-main-row2"></div>
					<!-- 合作伙伴 -->
					<el-carousel height="310px" v-if="!tabFlag">
						<el-carousel-item v-for="item in iconArr.length > 8 ? 2 : 1" :key="item" :autoplay="false" type="card"
							arrow="never">
							<div class="row6-main-row3" v-if="!tabFlag && item == 1">
								<div v-for="(item, index) in iconArr" :key="index">
									<img :src="item.icon" />
								</div>
							</div>
							<div class="row6-main-row3" v-if="!tabFlag && iconArr.length > 8">
								<div v-for="(item, index) in iconArr.length - 8" :key="index">
									<img :src="iconArr[index + 8].icon" />
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
					<!-- 我们的客户 -->
					<el-carousel height="310px" v-if="tabFlag">
						<el-carousel-item v-for="item in iconArr.length > 8 ? 2 : 1" :key="item" :autoplay="false" type="card"
							arrow="never">
							<div class="row6-main-row3" v-if="item == 1">
								<div v-for="(item, index) in iconArr" :key="index">
									<img :src="item.icon" />
								</div>
							</div>
							<div class="row6-main-row3" v-if="iconArr.length > 8">
								<div v-for="(item, index) in iconArr.length - 8" :key="index">
									<img :src="iconArr[index + 8].icon" />
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
					<div></div>
					<!-- 				<el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="我们的客户" name="first">我们的客户</el-tab-pane>
          <el-tab-pane label="合作伙伴" name="second">合作伙伴</el-tab-pane>
        </el-tabs> -->
				</div>
			</div>
		</div>
		<div class="hr-line">
			<div class="hr-line-son"></div>
		</div>
		<div class="row9">
			<div class="row">
				<div class="row9-son">
					<div class="row9-son-title">真诚服务每一位客户尽力完成每一个产品</div>
					<!-- 					<div class="row9-son-row1">
            帝天维护:&nbsp;维护产品稳定、数据安全、客户体验&emsp;<a href="http://baidu.com"> 进一步了解&nbsp;> </a>
          </div> -->
					<div class="row9-son-row2">
						<div class="row9-son-row2-div" v-for="(item, index) in caseArr" :key="index"
							@click="goMoralExampleDetails(item.id)">
							<div class="row9-son-row2-div-img row9-son-row2-div-img1"
								:style="'background-image:url(' + item.picture_url + ');background-size: 100% 100%;background-repeat: no-repeat;'">
							</div>
							<div class="row9-son-row2-div-logo">
								<img :src="item.logo_url" />
							</div>
							<div class="row9-son-row2-div-text1">{{ item.name }}</div>
							<div class="row9-son-row2-div-text2" v-html="item.particulars">
								{{ item.particulars }}
							</div>
							<!-- 							<div class="row9-son-row2-div-text3">
								使用技术：{{item.technical_feature}} 
							</div> -->
							<div class="row9-son-row2-div-text4"></div>
							<div class="row9-son-row2-div-text5">主营：{{ item.main_content }}</div>
						</div>
					</div>
					<div class="row9-son-row3" @click="goMoralExample">查看更多案例</div>
				</div>
			</div>
		</div>
		<div class="row6-bottom">
			<div class="row">
				<div class="row6-bottom-son">
					<div>
						<div class="row6-bottom-son-row1">477,300,288</div>
						<div class="row6-bottom-son-row2"></div>
						<div class="row6-bottom-son-row3">抵御攻击</div>
					</div>
					<div>
						<div class="row6-bottom-son-row1">99.0%</div>
						<div class="row6-bottom-son-row2"></div>
						<div class="row6-bottom-son-row3">成功率</div>
					</div>
					<div>
						<div class="row6-bottom-son-row1">1v1</div>
						<div class="row6-bottom-son-row2"></div>
						<div class="row6-bottom-son-row3">大客户专享</div>
					</div>
					<div>
						<div class="row6-bottom-son-row1">7×24</div>
						<div class="row6-bottom-son-row2"></div>
						<div class="row6-bottom-son-row3">客户服务支持</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row7">
			<div class="row">
				<div class="row7-son">
					<div class="row7-son-main">
						<div class="row7-son-main-title">资讯动态</div>
						<div class="row7-son-main-son">
							<div class="row7-son-main-son-left">
								<div class="row7-son-main-son-left-top">
									<img style="width: 100%; height: 100%;"
										:src="informationArr[0] ? informationArr[0].picture_url : ''" />
								</div>
								<div class="row7-son-main-son-left-bottom">
									<div class="row7-son-main-son-left-bottom-left">
										<div class="day">
											<span class="day-son1">{{ informationArr[0].update_time[2] }}</span>
											<br />
											<span
												class="day-son2">{{ informationArr[0].update_time[0] }}-{{ informationArr[0].update_time[1] }}
											</span>
										</div>
									</div>
									<div class="row7-son-main-son-left-bottom-right"
										@click="goInformationDetails(informationArr[0])">
										<div class="row7-son-main-son-left-bottom-right-son1">
											{{ informationArr[0].headline }}
										</div>
										<div class="row7-son-main-son-left-bottom-right-son2"
											v-html="informationArr[0].particulars">
											{{informationArr[0].particulars}}
										</div>
									</div>
								</div>
							</div>
							<div class="row7-son-main-son-right">
								<div class="row7-son-main-son-right-row1" v-if="informationArr.length > 1">
									<div class="row7-son-main-son-right-row1-left"
										@click="goInformationDetails(informationArr[1])">
										<div class="row7-son-main-son-right-row1-left-son1">
											{{ informationArr[1].headline }}
										</div>
										<div class="row7-son-main-son-right-row1-left-son2"
											v-html="informationArr[0].particulars">
											{{informationArr[1].particulars}}
										</div>
									</div>
									<div class="row7-son-main-son-right-row1-right">
										<div class="row7-son-main-son-right-row1-right-son">
											<span
												class="row7-son-main-son-right-row1-right-son-day">{{ informationArr[1].update_time[2] }}</span>
											<br />
											<span
												class="row7-son-main-son-right-row1-right-son-years">{{ informationArr[1].update_time[0] }}-{{ informationArr[1].update_time[1] }}</span>
										</div>
									</div>
								</div>
								<div class="row7-son-main-son-right-row2"
									v-for="(item, index) in informationArr.length > 7 ? 4 : informationArr.length "
									v-if="informationArr.length > Number(index) + 2" :key="index"
									@click="goInformationDetails(informationArr[index + 2])">
									<div>{{ informationArr[index + 2].headline }}</div>
									<div>{{ informationArr[index + 2].create_time }}</div>
								</div>
							</div>
						</div>
						<div class="row7-son-main-btn" @click="goInformation">更多资讯&nbsp;></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			// 判断用户点击的图标下标
			index: 0,
			iconArr: [{
				icon: require('../../static/img/hbkh/IBM.png')
			},
			{
				icon: require('../../static/img/hbkh/北京高信达通信.png')
			},
			{
				icon: require('../../static/img/hbkh/定制巴士.png')
			},
			{
				icon: require('../../static/img/hbkh/福建华博教育.png')
			},
			{
				icon: require('../../static/img/hbkh/福州日报社.png')
			},
			{
				icon: require('../../static/img/hbkh/南威.png')
			},
			{
				icon: require('../../static/img/hbkh/顺丰速运.png')
			},
			{
				icon: require('../../static/img/hbkh/网龙网络.png')
			},
			{
				icon: require('../../static/img/hbkh/伟思国瑞.png')
			},
			{
				icon: require('../../static/img/hbkh/星网锐捷.png')
			},
			{
				icon: require('../../static/img/hbkh/易联众.png')
			},
			{
				icon: require('../../static/img/hbkh/知鱼.png')
			},
			{
				icon: require('../../static/img/hbkh/中庚科技.png')
			},
			{
				icon: require('../../static/img/hbkh/中锐网络.png')
			},
			{
				icon: require('../../static/img/hbkh/yd.jpg')
			},
			{
				icon: require('../../static/img/hbkh/lt.jpg')
			}
			],
			caseArr: [], //所有案例
			// 轮播图数组
			bannerArr: [],
			smailArr: [{
				name: '多领域商店',
				img: require('../../static/img/g-dlysc.png'),
				imgClick: require('../../static/img/b-dlysc.png'),
				title: '多领域商城 ———————— 易销微信小程序商城',
				main: '新一代电商解决方案,适用于各行业模板,风格多样,省时省心,打造您的"私域流量"人专属商城。通过推进客户数字化、渠道数字化和供应链数字化建设，助力品牌商实现人、货、场的数字化重构，最终提升企业的经营效能和组织效能。',
				icon: require('../../static/img/dlysd.png'),
				label: [
					'商品展示', '订单管理', '客户引流', '物流互动', '客户管理', '提现快捷'
				]
			}, {
				name: '软件开发',
				title: '软件开发----业务场景定制的一体化解决方案',
				main: '超越传统模式，控制成本，为客户量身定制。从极速设计到可视化开发，前后端全形式支持。多维度数据保护，多渠道售前售后服务，全天候保障。',
				img: require('../../static/img/g-rjkf.png'),
				imgClick: require('../../static/img/b-rjkf.png'),
				icon: require('../../static/img/rjkf.png'),
				label: [
					'高端网站建设', 'OA系统', 'ERP 管理系统', '小程序开发', 'app开发', '微信', '公众号', '大数据', '物联网', '供应链'
				]
			}, {
				name: '大数据平台',
				title: '大数据平台----一站式智能数据管理平台',
				main: '以大数据全栈技术能力为支撑，提供数据接入、治理、处理、管理、服务能力，实现一站式数据全生命周期管理，做到数据“进得来、管得了、治理好、看得见、控得住、可共享”，帮助客户高效、低成本的管理数据资产，发挥数据效能。',
				img: require('../../static/img/g-bingtu.png'),
				imgClick: require('../../static/img/b-bingtu.png'),
				icon: require('../../static/img/dsjpt.png'),
				label: [
					'可视化数据分析', '深度数据挖掘', '业务数据预测', '搜索引擎', '自定义工具', 'APP统计分析推送'
				]
			}, {
				name: '网站搭建',
				title: '网站搭建----企业智慧门户',
				main: '以服务中心、运营中心和运维中心为主体，兼顾个性化应用扩展，符合安全防护要求，具有高可靠、高性能、易扩展特性，实现对企业门户网站群的全生命周期管理。',
				img: require('../../static/img/g-wzjs.png'),
				imgClick: require('../../static/img/b-wzjs.png'),
				icon: require('../../static/img/wzjs.png'),
				label: [
					'企业集团网站', '互联网端', '新媒体', '用户数据分析', '人工智能', '运营效率', '降低管理成本', '智能化'
				]
			}, {
				name: '微信运营',
				title: '微信运营----微信多方运营',
				main: '新一代电商解决方案,适用于各行业模板,风格多样,省时省心,打造您的"私域流量"人专属商城。',
				img: require('../../static/img/g-wxyy.png'),
				imgClick: require('../../static/img/b-wxyy.png'),
				icon: require('../../static/img/wxyy.png'),
				label: [
					'商品展示', '订单管理', '客户引流', '物流互动', '客户管理', '提现快捷'
				]
			},
			{
				name: '大数据平台',
				title: '大数据平台----一站式智能数据管理平台',
				main: '以大数据全栈技术能力为支撑，提供数据接入、治理、处理、管理、服务能力，实现一站式数据全生命周期管理，做到数据“"进得来、管得了、治理好、看得见、控得住、可共享”，帮助客户高效、低成本的管理数据资产，发挥数据效能。',
				img: require('../../static/img/g-bingtu.png'),
				imgClick: require('../../static/img/b-bingtu.png'),
				icon: require('../../static/img/dsjpt.png'),
				label: [
					'商品展示', '订单管理', '客户引流', '物流互动', '客户管理', '提现快捷'
				]
			}, {
				name: '多领域商店',
				title: '多领域商城 ———————— 易销微信小程序商城',
				main: '新一代电商解决方案,适用于各行业模板,风格多样,省时省心,打造您的"私域流量"人专属商城。通过推进客户数字化、渠道数字化和供应链数字化建设，助力品牌商实现人、货、场的数字化重构，最终提升企业的经营效能和组织效能。',
				img: require('../../static/img/g-dlysc.png'),
				imgClick: require('../../static/img/b-dlysc.png'),
				icon: require('../../static/img/dlysd.png'),
				label: [
					'商品展示', '订单管理', '客户引流', '物流互动', '客户管理', '提现快捷'
				]
			},
			],
			arr: [

				'商品展示',
				'订单管理',
				'客户引流',
				'物流互动',
				'客户管理',
				'提现快捷',
				'订单管理',
				'商品展示',
			],
			phone: '',
			code: '',
			flag: true,
			name: '',
			tabFlag: true,
			// 控制上下移动切换对应内容
			classFlag: '0',
			// 控制左右切换
			leftStyle: true,
			informationArr: [{
				update_time: '',

			}], //资讯数组
			planArr: [{
				name: '智慧政务',
				title: '智慧政务-------构建政务公开平台',
				main: '通过规范政务服务事项，实现政务服务线上和线下多渠道的统一申请、统一受理、集中办理、统一反馈和全流程监督等功能，有效解决办事系统繁杂、数据共享不畅、业务协同不足等问题',
				icon: [
					require('../../static/img/logo/福建交通人才服务中心.png'),
					require('../../static/img/logo/华博教育.png'),
					require('../../static/img/logo/网龙网络公司.png'),
				]
			},
			{
				name: '智慧城市',
				title: '智慧城市------用数据智能构建“城市大脑”',
				main: '以数据为核心建设城市运营指挥中心，用数据智能技术推动城市管理手段、管理模式、管理理念创新，加快智慧城市建设。',
				icon: [
					require('../../static/img/logo/中国移动.png'),
					require('../../static/img/logo/武夷山水.png'),
					require('../../static/img/logo/邵武市.png'),
					require('../../static/img/logo/拿口镇.png'),
				]
			},
			{
				name: '智慧园区',
				title: '智慧园区------促进园区资源合理整合',
				main: '建立园区大数据平台管理平台和产业地图，提升园区智慧化管理水平，提升运营效率，加速业务敏捷创新，提升服务体验，掌握园区企业发展动态，扶持企业健康快速发展。',
				icon: []
			},
			{
				name: '智慧党建',
				title: '智慧党建------赋能“党、纪、团、群”工作更高效、更智慧',
				main: '有效支撑跨地域覆盖、方便基层工作落实；提供互动交流平台，多样的宣传教育和组织生活，加强组织吸引力和凝聚力',
				icon: [
					require('../../static/img/logo/网龙网络公司.png'),
					require('../../static/img/logo/三进三民-白.png'),
					require('../../static/img/logo/党务易通.png')
				]
			},
			{
				name: '智慧医疗',
				title: '智慧医疗------助力医院管理效率提高80%',
				main: '全新智慧OA，让全院员工移动办公体验改善、“三重一大”管理更科学，多类费用管控更高效。',
				icon: []
			},
			{
				name: '智慧零售',
				title: '智慧零售------构建零售全域解决方案',
				main: '为零售企业数据化运营、用户运营、店内选品、门店选址、营销互动等业务提供数据基础和数据服务，帮助企业完成高质量的数字化转型。',
				icon: []
			},
			{
				name: '融媒体平台',
				title: '融媒体平台------助力打造全员媒体，强化媒体机构的传播力',
				main: '通过一次性采集、智能化编辑、多媒体呈现、全渠道发布，促进传播业态由矩阵型管理升级为集约型管理，有效提高各类型融媒体平台技术实力，进而推动媒体融合向纵深发展。',
				icon: []
			},
			],
		};
	},
	created() {
		var that = this;

		// 获取官网列表
		this.$axios
			.get('/atiigManage/list')
			.then((res) => {
				console.log(res.data);
				if (res.status == 200) {
					res.data.list.forEach(item => {
						item.picture_url = this.baseUrl + item.picture_url;
					});
					// that.bannerArr.push(res.data.list[0]);
					// console.log(res.data.list);
					that.bannerArr = res.data.list;
					console.log(that.bannerArr);
				}
			})
			.catch((err) => {
				console.log(err);
			});
		// 获取案例列表
		this.$axios.get('/case/list', {
			params: {
				pageSize: 3
			}
		}).then(res => {
			if (res.status == 200) {
				this.caseArr = res.data.list;
				this.caseArr.forEach(item => {
					item.picture_url = this.baseUrl + item.picture_url;
					item.logo_url = this.baseUrl + item.logo_url;
				});
				console.log(this.caseArr);
			}
		}).catch(err => {
			console.log(err);
		});
		// 获取资讯列表接口
		this.$axios
			.get('/mseManage/list')
			.then((res) => {
				this.informationArr = res.data.list;
				this.informationArr.forEach(item => {
					item.update_time = item.update_time.split(" ")[0];
					item.create_time = item.update_time.split(" ")[0];
					item.picture_url = this.baseUrl + item.picture_url;
				});
				this.informationArr.forEach(it => {
					it.update_time = it.update_time.split("-");
				});
			})
			.catch((err) => {
				console.log(err);
			});
		this.$forceUpdate();
	},
	methods: {
		goPlan() {
			this.$router.push('plan');
		},
		// 点击跳转到案例详情页
		goMoralExampleDetails(id) {
			console.log(id);
			sessionStorage.setItem('id', id);
			this.$router.push({
				name: 'moralExampleDetails',
				params: {
					id: id
				}
			});
		},
		// 点击预约跳转到预约表单位置
		goYuyue() {
			window.scrollTo(0, document.documentElement.scrollHeight - document.documentElement.clientHeight);
		},
		// 点击跳转案例页
		goMoralExample() {
			console.log('点击了');
			this.$router.push('moralExample');
		},
		// 跳转到资讯方法
		goInformation() {
			// sessionStorage.setItem('index','/Information');
			this.$router.push('Information');
		},
		goInformationDetails(item) {
			sessionStorage.setItem('id', item.id);
			this.$router.push({
				name: 'informationDetails',
				params: {
					id: item.id,
				},
			});
		},
		// 切换产品与服务介绍
		goBox(key) {
			console.log(key);
			this.index = key;
			this.$refs.boxtIitle[key].style.animation = 'lefteaseinAnimate 1s ease 1';
			this.$refs.boxtIitle[key].style.animationFillMode = 'forwards';
		},
		// 左右移动产品div方法
		left(key) {
			// console.log(key);
			if (key == 1) {
				document.getElementById('row3-son2-son-main').style.left = '0px';
			} else if (key == 2) {
				document.getElementById('row3-son2-son-main').style.left = '-1200px';
			}
		},
		Click(index) {
			this.classFlag = index;
		},
		Move(i) {
			if (i == 1) {
				this.tabFlag = true;
			} else {
				this.tabFlag = false;
			}
		},
		Switch(i) {
			if (i == 1) {
				this.tabFlag = true;
			} else {
				this.tabFlag = false;
			}
		},
	},
};
</script>

<style scoped>
body,
html {
	margin: 0;
	border: 0;
	padding: 0;
	width: 100%;
}

.main {
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
}

@keyframes leftextAnimate {
	0% {
		transform: translateX(-30px);
		opacity: 0;
	}

	100% {
		transform: translateX(0px);
		opacity: 1;
	}
}

/*页面加载文字移入动画*/
@keyframes lefteaseinAnimate {
	0% {
		transform: translateY(-30px);
		opacity: 0;
	}

	100% {
		transform: translateY(0px);
		opacity: 1;
	}

}

.row {
	width: 1200px;
	margin: 0 auto;
}

.row1 {
	position: sticky;
	top: 0;
	border-bottom: 1px solid #e9ebf3;
	z-index: 150;
	background-color: #fff;
}

.banner-box {
	width: 100%;
	height: 100%;
	background-image: url(../../static/img/banner.jpg);
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.header-box {
	width: 1200px;
	height: 60px;
	line-height: 59px;
	margin: 0 auto;
	/* z-index: 1200 !important; */
	position: relative;
	background-color: #fff;
	overflow: hidden;
	bottom: 1px;
}

.landray-logo {
	float: left;
	margin-top: 18px;
}

.header-left-box-right {
	position: relative;
	float: right;
	left: 21px;
	margin-left: 20px;
	line-height: 30px;
	text-align: center;
	margin-top: 15px;
	cursor: pointer;
	width: 86px;
	height: 30px;
	background: #4285f4;
	border-radius: 2px;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: bold;
	color: #ffffff;
}

.header-left {
	width: 100%;
	height: 100;
	display: flex;
	justify-content: space-between;
}

.header-left-box {
	float: left;
	/* margin-top: 18px; */
	height: 30px;
	width: 160px;
	background-image: url(../../static/img/logo-ditian-b.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.header-right {
	display: inline-block;
	width: 58%;
	height: 100%;
}

.header-right-ul {
	float: right;
	position: relative;
	top: -14px;
	z-index: 10;
	list-style-type: none;
}

.header-right-ul>div {
	width: 36px;
	height: 18px;
	position: absolute;
	border-bottom: 4px solid #4285f4;
	left: 55px;
	width: 40px;
	height: 0;
	bottom: 0;
	transition: all 0.3s;
}

.header-right-ul-li {
	font-size: 14px;
	color: #20242b;
	transition: all 0.3s;
	display: inline-block;
	vertical-align: middle;
}

.header-right-ul>li {
	position: relative;
	float: left;
	margin: 0 18px;
	padding: 0 4px;
	font-size: 14px;
	text-align: center;
	display: list-item;
	cursor: pointer;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #20242b;
}

.header-right-ul>li:hover {
	color: #4285f4;
}

.row2 {
	width: 100%;
	height: 480px;
	/* background-image: url(../../static/img/banner.jpg); */
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.row2-div-son1 {
	width: 100%;
	width: 392px;
	height: 34px;
	color: #ffffff;
	font-size: 34px;
	padding: 97px 0 0 0;
}

.row2-div-son2 {
	width: 100%;
	width: 500px;
	height: 45px;
	color: #ffffff;
	font-size: 45px;
	padding: 24px 0 0 0;
}

.row2-div-son3 {
	width: 100%;
	width: 580px;
	height: 20px;
	padding: 42px 0 0 0;
	font-size: 20px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #ffffff;
}

.row2-div-son4 {
	width: 136px;
	height: 40px;
	background: #008bfa;
	border-radius: 2px;
	margin: 72px 0 0 0;
	/* text-align: center; */
	line-height: 40px;
}

.row2-div-son4-btn {
	margin: 0 auto;
	width: 107px;
	height: 17px;
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}

.row3 {
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0px 5px 5px #ecedef;
}

.row3-son1 {
	width: 100%;
	padding-top: 39px;
	font-size: 30px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #20242b;
	text-align: center;
}

.row3-son2 {
	width: 1200px;
	height: 200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	position: relative;
}

.row3-son2>div {
	width: 100%;
	position: relative;
	overflow: hidden;
}

#row3-son2-son-main {
	position: absolute;
	width: 2400px;
	left: 0;
	height: 200px;
	overflow: hidden;
	transition: left 0.5s;
}

.row3-son2-son-main-right {
	left: -1200 !important;
}

.row3-son2-box {
	width: 240px;
	height: 120px;
	margin-bottom: 43px;
	display: inline-block;
}

.row3-son2-img {
	margin-top: 47px;
	display: flex;
	justify-content: center;
}

.row3-son2-text {
	text-align: center;
	font-size: 15px;
	font-family: PingFang SC Medium;
	font-weight: 550;
	color: #4285f4;
	margin-top: 17px;
}

.row4 {
	width: 100%;
	padding-bottom: 30px;
	background-color: #f7f8fc;
	height: 332px;
	/* 		transition: all 0.6s; */
}

.row4-son {
	height: 100%;
	display: flex;
	justify-content: space-between;
}

.row4-son-box1 {
	width: 79%;
	height: 100%;
	animation: lefteaseinAnimate 1s ease 1;
	animation-fill-mode: forwards;
	transition: all 0.6s;
	/* background-color: #00FF00; */
}

.row4-son-box2 {
	width: 22%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: lefteaseinAnimate 1s ease 1;
	animation-fill-mode: forwards;
	transition: all 0.6s;
}

.row4-son-box2-img>img {
	width: 240px;
}

.row4-son-box1-row1 {
	height: 22px;
	font-size: 22px;
	font-family: PingFang SC Medium;
	font-weight: 540;
	color: #20242b;
	margin: 49px 0 0 0;
	padding-left: 16px;
}

.row4-son-box1-row2 {
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: 550;
	color: #363f4e;
	margin: 19px 0 9px 0;
	padding-left: 16px;
}

.row4-son-box1-row3>a {
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #4285f4;
	cursor: pointer;
	margin: 9px 0 0 0;
	padding-left: 16px;
}

.row4-son-box1-row4 {
	width: 760px;
	display: flex;
	justify-content: left;
	flex-wrap: wrap;
}

.row4-son-box1-row4-box {
	height: 32px;
	background: #f7f8fb;
	border: 1px solid #b9c5d2;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 18px 0 0 16px;
	font-size: 12px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #737c8b;
	padding: 0 10px;
}

.row4-son-box1-row4-box1 {
	margin: 18px 0 0 0;
}

.row4-son-box1-row4-box2 {
	margin: 0 0 0 0;
}

.row4-son-box1-row4-box3 {
	margin: 0 0 0 16px;
}

.row4-son-box1-row4-btn {
	width: 120px;
	height: 40px;
	background: #4285f4;
	margin: 24px 0 0 0;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #ffffff;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
	margin-left: 16px;
}

.row5 {
	width: 100%;
	height: 590px;
	background-image: url(../../static/img/jjfa.jpg);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: relative;
}

.row5-son1 {
	position: absolute;
	top: 0;
	left: 0px;
	padding-top: 57px;
	width: 100%;
	text-align: center;
	font-size: 30px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #ffffff;
}

.row6 {
	width: 100%;
}

.row6-title {
	width: 100%;
	height: 30px;
	font-size: 30px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #20242b;
	padding: 40px 0 0 0;
	text-align: center;
}

.row6-main-row1-right {
	width: 74px;
	height: 14px;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: bold;
	color: #4285f4;
	position: absolute;
	right: 0;
	cursor: pointer;
}

.row6-main {
	position: relative;
}

.row6-main-row1 {
	width: 239px;
	height: 16px;
	margin: 0 auto;
	padding: 50px 0 5px 0;
	display: flex;
	justify-content: space-between;
}

.row6-main-row1-son1 {
	display: inline-block;
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: bold;
	color: #363f4e;
	padding-bottom: 32px;
}

.tab {
	color: #4285f4 !important;
	border-bottom: 4px solid #4285f4;
}

.row6-main-row1-son2 {
	display: inline-block;
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: bold;
	color: #363f4e;
	padding-bottom: 32px;
}

.row6-main-row2 {
	width: 1200px;
	height: 1px;
	background: #dddddd;
	margin: 15px 0 50px 0;
}

.row6-main-row3 {
	width: 100%;
	height: 310px;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.row6-main-row3>div {
	width: 282px;
	height: 120px;
	background: #ffffff;
	box-shadow: 2px 4px 10px 2px rgba(155, 176, 212, 0.2);
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 44px;
}

.row6-main-row3>div>img {
	height: 48px;
}

.row8 {
	width: 100%;
	height: 540px;
	background-image: url(../../static/img/bj-yy.jpg);
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.row8-son {
	width: 100%;
	text-align: center;
	color: #ffffff;
}

.row8-son>div:nth-child(1) {
	padding-top: 57px;
	padding-bottom: 27px;
	font-size: 30px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #ffffff;
}

.form-box {
	padding-top: 40px;
	width: 100%;
	height: 315px;
	background-color: #ffffff;
}

.line1 {
	margin: 10px 0 22px 0;
}

.line1>span {
	height: 50px;
	line-height: 50px;
	display: inline-block;
	margin-right: 20px;
	color: #000000;
}

.line1>input {
	box-sizing: border-box;
	width: 650px;
	height: 50px;
	padding: 14px 20px;
	font-size: 16px;
	background: #fff;
	border: 1px solid #e9ebf3;
	outline: none;
}

.line2 {
	/* height: 70px; */
	margin: 10px 0 20px 0;
	position: relative;
}

.line2>span {
	height: 50px;
	line-height: 50px;
	display: inline-block;
	margin-right: 20px;
	color: #000000;
}

.line2>input {
	box-sizing: border-box;
	width: 360px;
	height: 50px;
	padding: 14px 20px;
	font-size: 16px;
	background: #fff;
	border: 1px solid #e9ebf3;
	outline: none;
}

.line2>button {
	cursor: pointer;
	vertical-align: top;
	margin: 0 5px;
	height: 50px;
	width: 100px;
	text-align: center;
	border: none;
	background-color: transparent;
	font-size: 16px;
	color: #4285f4;
	outline: none;
}

.line2>p {
	position: absolute;
	color: #f0465c;
	left: 305px;
	top: 41px;
	text-align: left;
	font-size: 12px;
}

.line4 {
	width: 120px;
	height: 40px;
	background: #4285f4;
	border-radius: 2px;
	margin: 24px auto 11px auto;
	line-height: 40px;
}

.line3 {
	margin-top: 2px;
	font-size: 14px;
	color: #b9c5d2;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: #f1f1f1;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f1f1f1;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #dbdbdb;
}

.row6-bottom {
	width: 100%;
	height: 184px;
	background-image: url(../../static/img/ab03.jpg);
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.row6-bottom-son {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
}

.row6-bottom-son>div {
	height: 100%;
	/* border: 1px solid #FFFFFF; */
}

.row6-bottom-son>div:nth-child(4) {
	margin-left: 10px;
	width: 85px;
}

.row7-son {
	width: 100%;
	height: 627px;
}

.row7-son-main {
	width: 100%;
	height: 100%;
}

.row7-son-main-btn {
	width: 100%;
	text-align: center;
	cursor: pointer;
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: 530;
	color: #4285f4;
}

.row7-son-main-title {
	padding-top: 56px;
	margin-bottom: 28px;
	text-align: center;
	font-size: 30px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #20242b;
}

.row7-son-main-son {
	width: 100%;
	height: 68%;
	display: flex;
	justify-content: space-between;
}

.row7-son-main-son>div {
	width: 48%;
	height: 368px;
	/* border: 1px solid #0000FF; */
}

.row7-son-main-son-left {
	width: 100%;
	height: 100%;
}

.row7-son-main-son-left-top {
	width: 100%;
	height: 65%;
}

.row7-son-main-son-left-bottom {
	width: 100%;
	height: 35%;
	background-color: #f5f6fb;
	display: flex;
	justify-content: space-between;
}

.row7-son-main-son-left-bottom-left {
	width: 20.5%;
	height: 100%;
	/* border: 1px solid #00FF00; */
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.day {
	width: 100px;
	height: 80px;
}

.row7-son-main-son-left-bottom-right {
	width: 78.5%;
	height: 100%;
	/* border: 1px solid #00FF00; */
}

.day-son1 {
	width: 100%;
	color: #9497a4;
	font-size: 28px;
}

.day-son2 {
	width: 100%;
	color: #9497a4;
	font-size: 20px;
}

.row7-son-main-son-left-bottom-right-son1 {
	width: 93%;
	padding: 12px 0;
	border-bottom: 1px solid #d4d6db;
	font-size: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.row7-son-main-son-left-bottom-right-son2 {
	width: 93%;
	padding-top: 24px;
	/* display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; */
	overflow: hidden;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #9497a4;
	height: 38px;
	text-overflow: ellipsis;

}

.row7-son-main-son-right-row1 {
	width: 100%;
	display: flex;
	justify-content: space-between;
	/* margin-bottom: 35px; */
}

.row7-son-main-son-right-row1-left {
	width: 79%;
	height: 100px;
	/* background-color: #00FF00; */
}

.row7-son-main-son-right-row1-right {
	width: 20%;
	height: 100px;
	/* background-color: #00FFFF; */
}

.row7-son-main-son-right-row1-left-son1 {
	padding: 5px 0 18px 0;
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #333333;
}

.row7-son-main-son-right-row1-left-son2 {
	border-top: 1px solid #e7e7e7;
	padding-top: 20px;
	/* 		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; */
	overflow: hidden;
	color: #e7e7e7;
	font-size: 15px;
	height: 41px;
	text-overflow: ellipsis;
}

.row7-son-main-son-right-row1-right-son {
	text-align: right;
	margin-top: 12px;
}

.row7-son-main-son-right-row1-right-son-day {
	color: #8d8d8d;
	font-size: 30px;
	padding-right: 17px;
}

.row7-son-main-son-right-row1-right-son-years {
	color: #8d8d8d;
	font-size: 18px;
}

.row7-son-main-son-right-row2 {
	width: 100%;
	border-bottom: 1px solid #dddddd;
	display: flex;
	justify-content: space-between;
}

.row7-son-main-son-right-row2>div {
	padding: 28px 0 20px 0;
	font-size: 15px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #333333;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.row7-son-main-son-right-row2>div:nth-child(1) {
	width: 450px;
}

.row7-son-main-son-right-row2>div:nth-child(1):hover {
	color: #4285f4;
	cursor: pointer;
}

.row7-son-main-son-right-row3 {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 28px 0 20px 0;
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #333333;
}


.row7-son-main-son-right-row3>div:nth-child(1) {
	width: 450px;
}

.row7-son-main-son-right-row3>div:nth-child(1):hover {
	color: #4285f4;
	cursor: pointer;
}

.row7-son-main-son-right-row1-left-son1:hover {
	color: #4285f4;
	cursor: pointer;
}

.row7-son-main-son-left-bottom-right-son1:hover {
	color: #4285f4;
	cursor: pointer;
}

.row7-son-main>a {}

.row9 {
	width: 100%;
}

.row9-son {
	width: 100%;
	/* border: 1px solid #000000; */
}

.row9-son-row2-div {
	position: relative;
	cursor: pointer;
	transition: all 0.6s;
}

.row9-son-row2-div:hover {
	transform: scale(1.05);
}

.row9-son-title {
	width: 100%;
	height: 41px;
	font-size: 30px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #20242b;
	text-align: center;
	padding-top: 90px;
	padding-bottom: 52px;
}

.row9-son-row1 {
	width: 1200px;
	height: 64px;
	background: #f6f9ff;
	border-radius: 3px;
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #363f4e;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 56px;
}

.row9-son-row1>a {
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #4285f4;
	cursor: pointer;
	text-decoration: none;
}

.row9-son-row2 {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.row9-son-row2>div {
	width: 336px;
	height: 406px;
	margin: 10px 0;
	box-shadow: 0px 4px 10px 0px rgba(155, 176, 212, 0.2);
	border-radius: 3px;
	/* border: 1px solid #000000; */
	/* background-color: #00FF00; */
	margin-bottom: 35px;
}

.row9-son-row2-div-img {
	width: 336px;
	height: 190px;
}


.row9-son-row2-div-logo {
	position: absolute;
	top: 150px;
	left: 20px;
	width: 72px;
	height: 72px;
	background: #FFFFFF;
	border-radius: 36px;
	box-shadow: 0px 4px 10px 0px rgba(155, 176, 212, 0.2);
  >img{
    width: 100%;
    height: 100%;
  }
}

.row9-son-row2-div-logo2 {
	position: absolute;
	top: 124px;
	left: 20px;
	width: 72px;
	height: 72px;
	background: linear-gradient(45deg, #3b6ee9, #4197ff);
	box-shadow: 0px 2px 10px 0px rgba(34, 24, 21, 0.1);
	border-radius: 36px;
}

.row9-son-row2-div-logo3 {
	position: absolute;
	top: 124px;
	left: 20px;
	width: 72px;
	height: 72px;
	background: #ffffff;
	box-shadow: 0px 2px 10px 0px rgba(34, 24, 21, 0.1);
	border-radius: 36px;
}

.row9-son-row2-div-logo>img {
	border-radius: 36px;
}

.row9-son-row2-div-logo2>img {
	border-radius: 36px;
}

.row9-son-row2-div-logo3>img {
	border-radius: 36px;
}

.row9-son-row2-div-text1 {
	margin-top: 56px;
	margin-left: 20px;
	height: 17px;
	font-size: 18px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #333333;
	line-height: 0px;
}

.row9-son-row2-div-text2 {
	margin-top: 15px;
	margin-left: 20px;
	width: 270px;
	height: 52px;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #999999;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}

.row9-son-row2-div-text3 {
	margin-top: 9px;
	margin-left: 20px;
	width: 300px;
	height: 15px;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #999999;
	line-height: 0px;
}

.row9-son-row2-div-text4 {
	/* margin-top: 21px; */
	width: 292px;
	height: 1px;
	background: #e5e5e5;
	margin: 21px auto 0 auto;
}

.row9-son-row2-div-text6 {
	width: 292px;
	height: 1px;
	background: #e5e5e5;
	margin: 46px auto 0 auto;
}

.row9-son-row2-div-text5 {
	margin: 26px 0 0 19px;
	height: 14px;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 400;
	color: #333333;
	line-height: 0px;
}

.row9-son-row3 {
	width: 140px;
	height: 40px;
	background: #ffffff;
	border: 1px solid #4285f4;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #4285f4;
	text-align: center;
	line-height: 40px;
	margin: 31px auto 52px auto;
	cursor: pointer;
}

.row6-bottom-son-row1 {
	margin-top: 35px;
	width: 200px;
	height: 27px;
	font-size: 30px;
	font-family: Roboto Mono;
	font-weight: 500;
	color: #ffffff;
}

.row6-bottom-son-row2 {
	margin-top: 38px;
	width: 32px;
	height: 2px;
	background: #ffffff;
}

.row6-bottom-son-row3 {
	margin-top: 23px;
	/* width: 56px; */
	height: 14px;
	font-size: 14px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #ffffff;
}

.el-carousel__button {
	background-color: #0a69c6;
}

.row5-son2 {
	width: 100%;
	height: 590px;
}

.row5-son2>div {
	display: flex;
	justify-content: space-between;
}

.row5-son2-left {
	width: 180px;
	height: 584px;
	margin-top: 6px;
	background: linear-gradient(180deg,
			rgba(32, 36, 43, 0.1),
			rgba(32, 36, 43, 0.6) 51%,
			rgba(32, 36, 43, 0.1));
}

.row5-son2-left>div {
	width: 180px;
	height: 50px;
	padding-left: 4px;
	font-size: 14px;
	text-align: center;
	line-height: 50px;
	/* background: linear-gradient(90deg, #20242B 0%, rgba(32, 36, 43, 0) 100%); */
	color: #b9c5d2;
	cursor: pointer;
}

.row5-son2-left>div:nth-child(9) {
	color: #ffffff;
}

.row5-son2-left-color {
	color: #4285f4;
	padding-left: 0px !important;
	border-left: 4px solid #4285f4;
	background-image: linear-gradient(90deg, #20242b, rgba(32, 36, 43, 0));
}

.row5-son2-right {
	padding-top: 108px;
	margin-top: 6px;
	width: 1000px;
	/* border: 1px solid #000000; */
	height: 400px;
}

.row5-son2-right>div {
	animation: leftextAnimate 1s ease 1;
	animation-fill-mode: forwards;
	transition: all 0.6s;
}

.row5-son2-right-text1 {
	padding-left: 50px;
	height: 23px;
	font-size: 24px;
	font-family: PingFang SC Medium;
	font-weight: 520;
	color: #ffffff;
	margin-top: 30px;
}

.row5-son2-right-text2 {
	padding-left: 50px;
	margin-top: 40px;
	width: 100%;
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #b9c5d2;
	line-height: 25px;
}

.row5-son2-right-text3 {
	padding-left: 50px;
	margin-top: 12px;
	width: 100%;
	height: 17px;
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #b9c5d2;
	line-height: 0px;
}

.row5-son2-right-btn {
	width: 140px;
	height: 40px;
	background: #4384f3;
	color: #ffffff;
	margin-top: 41px;
	margin-left: 50px;
	text-align: center;
	line-height: 40px;
}

.row5-son2-right-text4 {
	padding-left: 50px;
	margin-top: 54px;
	width: 100%;
	height: 16px;
	font-size: 16px;
	font-family: PingFang SC Medium;
	font-weight: bold;
	color: #ffffff;
}

.row5-son2-right-img {
	width: 100%;
	margin-top: 30px;
	margin-left: 23px;
}

.row5-son2-right-img>img {
	height: 35px;
	margin-left: 50px;
}

.row5-son2-right-img>img:nth-child(2) {
	margin-left: 88px;
}

.row5-son2-right-img>img:nth-child(3) {
	margin-left: 88px;
}

.hr-line {
	width: 100%;
	height: 2px;
	margin-top: 90px;
	position: relative;
	clear: both;
	overflow: hidden;
	background: #dddddd;
	/* background-color: #000000; */
}

.hr-line-son {
	width: 70%;
	height: 2px;
	position: relative;
	margin: 0 auto;
	clear: both;
	overflow: hidden;
	background: #ffffff;
}

.color-black {
	color: #363f4e;
}

.footer-box-son2>div:nth-child(2) {
	width: 25%;
}

.home-tabs-btn {
	position: absolute;
	top: 50%;
	width: 24px;
	height: 24px;
	margin-top: -5px;
	cursor: pointer;
	z-index: 12;
	transform: rotate(45deg);
}

.home-tabs-btn-left {
	left: 6px;
	border-bottom: 1px solid #737c8b;
	border-left: 1px solid #737c8b;
}

.home-tabs-btn-right {
	right: 7px;
	border-top: 1px solid #737c8b;
	border-right: 1px solid #737c8b;
}
</style>
