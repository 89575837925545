<template>
  <div id="app">
    <!-- <img src="./assets/logo.png"> -->
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './views/header.vue'
import Footer from './views/footer.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  methods: {}
}
</script>

<style>
html,
body {
  margin: 0;
  border: 0;
  padding: 0;
}

/*定义滚动条  宽*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(241, 241, 241, 0.4);
  display: none;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(241, 241, 241, 0.8);
}

/* 定义滑块 内阴影+圆角 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgba(219, 219, 219, 0.1);
}
</style>
