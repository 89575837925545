<template>
	<div class="main">
		<div class="row1">
			<img style="width: 100%;" src="../../../static/img/banner/banner_zxdt.png" />
			<div>
				<p>
					资讯动态
				</p>
				<p>
					在第一时间获取帝天动态，了解行业趋势
				</p>
			</div>
		</div>
		<div class="row2">
			<div class="row row2-son">资讯动态</div>
		</div>
		<div class="horizontal-line"></div>
		<div class="row3">
			<div class="row row3-son">
				<div :class="index == key ? 'row3-son-box row3-son-box1' : 'row3-son-box'" @click="move(key, item)"
					v-for="(item, key) in msgClassify" :key="key">
					{{ item.calss_name }}
				</div>
			</div>
		</div>
		<div class="row4">
			<div class="row row4-son">
				<div class="row4-son-left-box" v-for="(item, key) in informationArr" @click="goInformationDetails(item)"
					:key="key" @mouseover="yiRu(key, item)" @mouseout="yiChu(key)">
					<div class="row4-son-left-box-img">
						<img :src="item.picture_url?item.picture_url: '../../../static/img/1.jpg'" />
					</div>
					<div class="row4-son-left-box-text">
						<div class="row4-son-left-box-text-title" ref="title">
							{{ item.headline }}
						</div>
						<div class="row4-son-left-box-text-main" v-html="item.particulars">
							{{ item.particulars }}
						</div>
						<div class="row4-son-left-box-text-date">
							{{ item.update_time }}
						</div>
						<div class="row4-son-left-box-btn">
							<div v-for="(it, index) in item.classify" :key="index">
								{{ it }}
							</div>
						</div>
					</div>
				</div>
				<div v-if="informationArr.length == 0" style="width: 924px;font-weight: bold; font-size: 26px;">
					暂无该类型资讯
				</div>
				<div class="row4-son-right-box">
					<div class="row4-son-right-box-title">热门标签</div>
					<div class="label-box">
						<!-- <div class="label" v-for="(item,index) in msgClassify" :key="index">{{item.calss_name}}</div> -->
						<div class="label">品牌文化</div>
						<div class="label">市场活动</div>
						<div class="label">媒体报道</div>
						<div class="label">行业动态</div>
						<div class="label">签约验收</div>
					</div>
				</div>
				<div class="row4-pages">
					<el-pagination background layout="prev, pager, next" :total="total" :pageSize="pageSize"
						:pageNumber="pageNumber" @current-change="change" v-if="informationArr.length>0">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				index: 0,
				informationArr: [],
				total: 10,
				pageSize: 6,
				pageNumber: 1,
				msgClassify: [],
				classsInfo: []
			}
		},
		created() {
			// 获取资讯分类列表接口
			this.$axios
				.get('/pub/msgClassify/list')
				.then((res) => {
					console.log('data', res.data);
					this.msgClassify = res.data.list
					// 获取资讯列表接口
					this.$axios
						.get('/mseManage/list', {
							params: {
								pageSize: 6,
								pageNumber: 1,
							},
						})
						.then((res) => {
							this.total = res.data.total
							this.informationArr = res.data.list
							this.informationArr.forEach((item) => {
								item.classify = item.classify.split(',');
								if(item.picture_url){
									item.picture_url = this.baseUrl + item.picture_url;
								}
							})
							var newArr = []
							this.informationArr.forEach((it, index) => {
								it.classify.forEach((a) => {
									this.msgClassify.forEach(b => {
										if (a == b.id) {
											newArr.push({
												id: index,
												name: b.calss_name
											});
										}
									})
								})
								it.classify = [];
								newArr.forEach(t => {
									if (index == t.id) {
										it.classify.push(t.name);
									}
								})
							})
						})
						.catch((err) => {
							console.log(err)
						})
				})
				.catch((err) => {
					console.log(err)
				})
		},
		methods: {
			getmsgClassify() {
				this.$axios
					.get('/pub/msgClassify/list')
			},
			getMseManage() {
				this.$axios
					.get('/mseManage/list', {
						params: {
							pageSize: 6,
							pageNumber: 1,
						},
					})
			},
			getList(total, pageNumber, pageSize) {
				this.$axios
					.get('/mseManage/list', {
						params: {
							pageSize: pageSize,
							total: total,
							pageNumber: pageNumber,
						},
					})
					.then((res) => {
						console.log(res)
						if (res.status == 200) {
							this.total = res.data.total
							this.pageSize = res.data.pageSize
							this.pageNumber = res.data.pageNumber
							this.informationArr = res.data.list
							this.informationArr.forEach((item) => {
								item.classify = item.classify.split(',')
								if(item.picture_url){
									item.picture_url = this.baseUrl + item.picture_url;
								}
							})
							this.informationArr.forEach((it, index) => {
								it.classify.forEach((a) => {
									this.msgClassify.forEach(b => {
										if (a == b.id) {
											this.classsInfo.push({
												id: index,
												name: b.calss_name
											});
										}
									})
								})
								it.classify = [];
								this.classsInfo.forEach(t => {
									if (index == t.id) {
										it.classify.push(t.name);
									}
								})
								this.classsInfo = [];
							})
							document.scrollingElement.scrollTop = '20%';
							document.scrollingElement.scrollLeft = 0;
						}
					})
					.catch((err) => {
						console.log(err)
					})
			},
			//翻页功能
			change(e) {
				this.getList(this.total, e, this.pageSize)
			},
			yiRu(key, item) {
				// console.log(item);
				this.$refs.title[key].style.color = '#4285F4'
			},
			yiChu(key) {
				this.$refs.title[key].style.color = '#737c8b'
			},
			move(key, item) {
				this.index = key
				if (item.id == 11) {
					item.id = ''
				}
				this.$axios
					.get('/mseManage/list', {
						params: {
							classify: item.id,
							pageSize: 6
						},
					})
					.then((res) => {
						this.total = res.data.total
						this.pageSize = res.data.pageSize
						this.pageNumber = res.data.pageNumber
						console.log(this.msgClassify)
						this.informationArr = res.data.list
						this.informationArr.forEach((item) => {
							item.classify = item.classify.split(',');
							item.picture_url = this.baseUrl + item.picture_url;
						})
						this.informationArr.forEach((it, index) => {
							it.classify.forEach((a) => {
								this.msgClassify.forEach(b => {
									if (a == b.id) {
										this.classsInfo.push({
											id: index,
											name: b.calss_name
										});
									}
								})
							})
							it.classify = [];
							this.classsInfo.forEach(t => {
								if (index == t.id) {
									it.classify.push(t.name);
								}
							})
							this.classsInfo = [];
						})
					})
					.catch((err) => {
						console.log(err)
					})
			},
			goInformationDetails(item) {
				sessionStorage.setItem('id', item.id)
				this.$router.push({
					name: 'informationDetails',
					params: {
						id: item.id,
					},
				})
			},
		},
	}
</script>

<style scoped>
	body,html{
		margin: 0;
		border: 0;
		padding: 0;
		width: 100%;
	}
	.main{
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
	}
	.row {
		width: 1200px;
		margin: 0 auto;
	}

	.row1 {
		width: 100%;
		position: relative;
	}
	.row1>div {
		position: absolute;
		top: 20%;
		z-index: 9;
		color: #ffffff;
		width: 100%;
		text-align: center;
	}
	.row1>div>P:nth-child(1){
		font-size: 36px;
	}
	.row1>div>P:nth-child(2){
		font-size: 20px;
	}
	

	.row2 {
		width: 100%;
	}

	.row2-son {
		padding-top: 70px;
		padding-bottom: 56px;
		text-align: center;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #20242b;
	}

	.horizontal-line {
		width: 100%;
		height: 1px;
		background: #e9ebf3;
	}

	.row3-son {
		display: flex;
		flex-wrap: wrap;
	}

	.row3-son-box {
		width: 120px;
		height: 48px;
		background: #ffffff;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #363f4e;
		text-align: center;
		line-height: 48px;
		display: inline-block;
		cursor: pointer;
	}

	.row3-son-box1 {
		background: #4285f4;
		color: #ffffff;
	}

	.row4 {
		width: 100%;
		min-height: 465px;
		background-color: #f7f8fb;
	}

	.row4-son {
		position: relative;
		padding-top: 24px;
	}

	.row4-son-left-box {
		width: 924px;
		height: 196px;
		background: #ffffff;
		box-shadow: 0px 1px 20px 0px rgba(115, 124, 139, 0.2);
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
	}

	.row4-son-left-box-img {
		width: 30%;
		height: 100%;
		display: flex;
		align-items: center;
		margin-left: 20px;
	}

	.row4-son-left-box-img>img {
		width: 288px;
		height: 156px;
	}

	.row4-son-left-box-text {
		width: 64%;
		position: relative;
	}

	.row4-son-left-box-text-title {
		padding-top: 19px;
		padding-right: 10px;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737c8b;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	.row4-son-left-box-text-main {
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737c8b;
		width: 90%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		padding-top: 17px;
		height: 20px;
	}

	.row4-son-right-box {
		width: 248px;
		height: 408px;
		background: #ffffff;
		border: 1px solid #e9ebf3;
		position: absolute;
		top: 24px;
		right: 0;
		/* padding: 16px; */
	}

	.row4-son-right-box-title {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242b;
		padding: 16px 0 20px 16px;
	}

	.row4-son-left-box-text-date {
		padding-top: 9px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737c8b;
	}

	.row4-son-left-box-btn {
		width: 100%;
		display: flex;
		justify-content: left;
		margin-top: 39px;
	}

	.row4-son-left-box-btn>div {
		width: 72px;
		height: 32px;
		background: #ffffff;
		border: 1px solid #dddfe1;
		border-radius: 4px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737c8b;
		text-align: center;
		line-height: 32px;
		margin-right: 12px;
		position: absolute;
		bottom: 20px;
	}

	.label-box {
		display: flex;
		flex-wrap: wrap;
		padding: 16px;
	}

	.label {
		padding: 11px 14px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #363f4e;
		background: #ffffff;
		border: 1px solid #dddfe1;
		border-radius: 2px;
		margin-right: 8px;
		margin-bottom: 16px;
	}

	.row4-pages {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-top: 62px;
		padding-bottom: 98px;
	}
</style>
