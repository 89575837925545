<template>
	<div class="main">
		<div class="row1">
			<img style="width: 100%;" src="../../../static/img/banner/banner-gydt.e08f9f5.jpg" />
			<div class="row1-title">
				<p>
					岗位招聘
				</p>
				<p>
					我们的热情不仅对顾客，还有准备加入的你
				</p>
			</div>
			<div class="row row1-son">
				<div class="row1-son-box">
					<div class="row1-son-box-input">
						<el-input placeholder="请输入岗位关键词" v-model="value">
							<i slot="prefix" class="el-input__icon el-icon-search"></i>
						</el-input>
					</div>
					<div class="row1-son-box-btn" @click="search()">搜索岗位</div>
				</div>
			</div>
		</div>
		<div class="row2">
			<div class="row row2-son">
				<div class="row2-son-row1">
					<div class="">工作地点 :</div>
					<ul>
						<li>全部</li>
						<li>福州市</li>
						<li>平潭县</li>
					</ul>
				</div>
				<div class="row2-son-row1 row2-son-row2">
					<div class="">职位类型 :</div>
					<ul>
						<li>全部</li>
						<li>技术类</li>
						<li>智能类</li>
						<li>销售类</li>
						<li>产品类</li>
						<li>市场类</li>
						<li>管理类</li>
						<li>商务类</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row3">
			<div class="row row3-son">
				<div :class="
            item.show == true ? 'row-son-box' : 'row-son-box row-son-box1'
          " v-for="(item, index) in jobsArr" ref="infoBox" :key="index">
					<div class="arrow-up" @click="showInfo(index)" v-if="item.show"></div>
					<div class="arrow-dowm" @click="showInfo(index)" v-if="!item.show"></div>
					<div class="row-son-box-title">
						{{ item.title }}
					</div>
					<div class="row-son-box-info">
						<div class="row-son-box-info-type">职能类型：{{ item.type }}</div>
						<div class="row-son-box-info-department">
							所属部门：{{ item.department }}
						</div>
						<div class="row-son-box-info-address">
							工作地点：{{ item.address }}
						</div>
						<div class="row-son-box-info-address">
							薪资: {{ item.salary }}
						</div>
						<div class="row-son-box-info-address">
							学历: {{ item.learn }}
						</div>
					</div>
					<div class="describe-box" v-if="item.jobs.length>0">
						<div class="describe-box-title">岗位要求</div>
						<div class="describe-box-main">
							<ol>
								<li v-for="(it, key) in item.jobs" :key="key">
									{{ it }}
								</li>
							</ol>
						</div>
					</div>
					<div class="describe-box" v-if="item.describe.length>0">
						<div class="describe-box-title">职位描述</div>
						<div class="describe-box-main">
							<ol>
								<li v-for="(it, key) in item.describe" :key="key">
									{{ it }}
								</li>
							</ol>
						</div>
					</div>
					<div class="requirements-box" v-if="item.requirements.length>0">
						<div class="requirements-box-title">任职要求</div>
						<div class="requirements-box-main">
							<ol>
								<li v-for="(it, key) in item.requirements" :key="key">
									{{ it }}
								</li>
							</ol>
						</div>
					</div>
					<div class="requirements-title">
						<div>若有意向,请将简历发送至 <span>liujiashan@fzditian.com</span> 邮箱</div>
					</div>
					<div class="info-box">
						<div class="info-btn" @click="goJobs(item)">立即申请</div>
					</div>
				</div>
				<div class="pages-box">
					<el-pagination background layout="prev, pager, next" :total="1" :pageSize="6">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				value: "", // 搜索框值
				jobsArr: [{
						title: "投标员",
						type: "商务类",
						department: "市场部门",
						salary: '4-6K（人数：2个）',
						address: "福州市",
						jobs: ['工作经验：1-3年'],
						learn: '大专',
						describe: [
							"负责招投标信息的搜集，投标文件的制作及标书中涉及的其他工作。",
							"负责投标文件的编辑制作，并按规定如期完成标书制作。",
							"与项目负责人、公司相关部门积极协调投标文件编制过程中的问题，确定投标文件按时投递。",
							"参标及项目跟进。",
						],
						requirements: [
							"熟悉政府采购、招标相关法律法规及流程。",
							"能独立编写招标文件，文字功底和协调能力较强。",
							"具有良好的团队合作能力。",
						],
						show: true,
					},
					{
						title: "初级测试工程师",
						type: "技术类",
						salary: '4-9k （人数：10个）',
						department: "产品研发部",
						jobs: ['工作经验：1-3年'],
						learn: '大专',
						address: "福州市",
						describe: [
							"了解测试需求，制定测试计划，编写测试用例。",
							"执行测试用例，编写bug，熟悉使用软件测试工具",
							"编写测试报告",
							"有性能测试经验者优先",
						],
						requirements: [],
						show: false,
					},
					{
						title: "前端技术员",
						type: "技术类",
						jobs: ['工作经验：1-3年'],
						learn: '本科',
						salary: '7-10k （人数：5个）',
						department: "产品研发部",
						address: "福州市",
						describe: [
							"计算机相关专业毕业。",
							"HTML基础扎实，精通vue框架，熟悉Element UI或者Ant Design组件，了解ES6/ES7、 Eslint、Less/Scss等",
						],
						requirements: [

						],
						show: false,
					},
					{
						title: "java",
						type: "技术类",
						salary: '6-11k(人数：5个）',
						jobs: ['工作经验：1-3年'],
						learn: '本科',
						department: "产品研发部",
						address: "福州市",
						describe: [
							"精通java以及springboot，熟悉spring cloud、mybatis、redis、zookeeper、nginx等，对微服务和分布式有较深的理解。熟悉mysql语法，了解ES、MQ。",
							"有高并发开发经验优先。",
						],
						requirements: [],
						show: false,
					}
				], // 招聘岗位数组
				key: 0, //用于判断用户是否点击展开隐藏按钮
			};
		},
		methods: {
			search() {
				console.log(this.value);
			},
			//点击展开隐藏岗位信息的方法
			showInfo(key) {
				console.log(key);
				this.jobsArr[key].show = !this.jobsArr[key].show;
			},
			//点击申请岗位的方法
			goJobs(item) {
				console.log(item);
				this.$router.push({
					name: "Jobs",
					params: {
						info: item,
					},
				});
			},
		},
	};
</script>

<style scoped>
	body,
	html {
		margin: 0;
		border: 0;
		padding: 0;
		width: 100%;
	}

	.main {
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
	}

	.row {
		width: 1200px;
		margin: 0 auto;
	}

	.row1 {
		width: 100%;
		position: relative;
	}

	.row1-title {
		position: absolute;
		top: 20%;
		z-index: 9;
		color: #ffffff;
		width: 100%;
		text-align: center;
	}

	.row1-title>p:nth-child(1) {
		font-size: 36px;
	}

	.row1-title>p:nth-child(2) {
		font-size: 20px;
	}


	.row1-son-box {
		position: absolute;
		bottom: -17%;
		margin: 0 auto;
		width: 1200px;
		height: 120px;
		background: #ffffff;
		box-shadow: 0px 6px 10px 0px rgba(115, 124, 139, 0.15);
		display: flex;
		justify-content: left;
		align-items: center;
		display: none;
	}

	.row1-son-box-input {
		margin-left: 80px;
		width: 896px;
		height: 40px;
	}

	.row1-son-box-btn {
		margin-left: 24px;
		text-align: center;
		line-height: 40px;
		width: 128px;
		height: 40px;
		background: #4285f4;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.row2 {
		width: 100%;
		height: 260px;
		display: none;
	}

	.row2-son-row1 {
		padding-top: 130px;
		display: flex;
		justify-content: left;
		align-items: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #363f4e;
	}

	.row2-son-row1>ul>li {
		display: inline-block;
		margin-left: 36px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #363f4e;
		cursor: pointer;
	}

	.row2-son-row1>ul>li:hover {
		color: #4285f4;
	}

	.row2-son-row1>ul>li:nth-child(1) {
		margin-left: 0;
	}

	.row2-son-row2 {
		padding-top: 0;
	}

	.row3 {
		width: 100%;
		background: #f7f8fb;
	}

	.row3-son {
		padding-top: 68px;
	}

	.row-son-box {
		position: relative;
		width: 1200px;
		overflow: hidden;
		background: #ffffff;
		box-shadow: 0px 1px 20px 0px rgba(115, 124, 139, 0.2);
		margin-bottom: 16px;
		transition: all 0.6s;
	}

	.row-son-box>div {}

	.row-son-box1 {
		height: 160px;
		transition: all 0.5s;
	}

	.row-son-box-title {
		/* 		padding-top: 45px; */
		padding: 45px 42px 0 42px;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4285f4;
	}

	.row-son-box-info {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737c8b;
		display: flex;
		justify-content: left;
		margin: 25px 42px 0 42px;
		padding-bottom: 47px;
		border-bottom: 1px solid #e5e5e5;
	}

	.row-son-box-info>div {
		margin-right: 50px;
	}

	.describe-box {
		padding: 0 42px;
	}

	.describe-box-title {
		margin-top: 48px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #363f4e;
	}

	.requirements-box {
		padding: 0 42px;
	}

	.requirements-title {
		color: #737c8b;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		padding: 20px 42px 40px 40px;
	}
	.requirements-title>div>span{
		cursor: pointer;
		color: #4285f4;
	}

	.describe-box-main>ol,
	.requirements-box-main>ol {
		margin-top: 26px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737c8b;
		line-height: 26px;
	}

	.requirements-box-title {
		margin-top: 59px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #363f4e;
	}

	.info-box {
		width: 100%;
		padding-top: 83px;
		padding-bottom: 43px;
		display: flex;
		justify-content: center;
		display: none;
	}

	.info-btn {
		width: 120px;
		height: 40px;
		background: #4285f4;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #ffffff;
		text-align: center;
		line-height: 40px;
	}

	.arrow-up {
		width: 19px;
		height: 19px;
		position: absolute;
		top: 80px;
		right: 50px;
		border-left: 1px solid #737c8b;
		border-top: 1px solid #737c8b;
		transform: rotate(45deg);
	}

	.arrow-dowm {
		width: 19px;
		height: 19px;
		position: absolute;
		top: 80px;
		right: 50px;
		border-right: 1px solid #737c8b;
		border-bottom: 1px solid #737c8b;
		transform: rotate(45deg);
	}

	.pages-box {
		padding-top: 52px;
		padding-bottom: 98px;
		width: 100%;
		display: flex;
		justify-content: center;
	}
</style>
