<template>
	<div class="main">
		<div class="row1">
			<div class="row row1-son">
				<div class="row1-son-main">
					<div class="row1-son-left">
						<div class="row1-son-left-title">
							{{ informationArr.headline }}
						</div>
						<div class="row1-son-left-date">
							发布人：{{ informationArr.issue_person }} &emsp;时间：{{ informationArr.update_time }}
						</div>
						<div class="row1-son-left-main" v-html="informationArr.particulars">
							{{ informationArr.particulars }}
						</div>
					</div>
					<div class="row1-son-right">
						<div class="row1-son-title">
							推荐资讯
						</div>
						<div class="row1-son-main">
							<ol>
								<li @click="goLast(recommended[index])" v-for="(item, index) in recommended.length > 7 ? 7
									: recommended.length" :key="index">
									{{ index + 1 }}.&nbsp;{{ recommended[index].headline }}
								</li>
							</ol>
						</div>
					</div>
				</div>
				<div>
					<fx></fx>
				</div>
				<div class="row1-son-lebal">
					<div class="row1-son-label">
						资讯标签 :
					</div>
					<div class="row1-son-btn" v-for="(item, index) in labelArr" :key="index">
						{{ item }}
					</div>
				</div>
				<div class="row1-son-last" v-if="dataArr.length > 0" @click="goLast(dataArr[0])">
					<div>
						上一篇 :
					</div>
					<div>
						{{ dataArr[0].headline }}
					</div>
				</div>
				<div class="row1-son-next" v-if="dataArr.length > 1" @click="goLast(dataArr[1])">
					<div>
						下一篇 :
					</div>
					<div>
						{{ dataArr[1].headline }}
					</div>
				</div>
			</div>
		</div>
		<div class="row2">
			<div class="row row2-son">
				<div class="row2-son-title">
					相关资讯
				</div>
				<div class="row2-son-box">
					<div class="row2-son-left-box" v-for="(item, key) in dataArr.length > 3 ? 3 : dataArr.length" :key="key"
						@click="goLast(dataArr[key])">
						<div class="row2-son-left-box-img">
							<img :src="dataArr[key].picture_url" />
						</div>
						<div class="row2-son-left-box-text">
							<div class="row2-son-left-box-text-title">
								{{ dataArr[key].headline }}
							</div>
							<div class="row2-son-left-box-text-main" v-html="dataArr[key].particulars">
								{{dataArr[key].particulars}}
							</div>
							<div class="row2-son-left-box-text-date">
								{{ dataArr.update_time }}
							</div>
							<div class="row2-son-left-box-btn">
								<div v-for="(it, index) in dataArr[key].classify" :key="index">
									{{ it }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import fx from '@/views/components/new_file.vue'
export default {
	components: {
		fx
	},
	data() {
		return {
			labelArr: [], //资讯详情标签
			informationArr: {}, //资讯详情
			msgClassify: [], //资讯分类数组
			dataArr: [], //相关资讯数组
			recommended: [] //推荐资讯数组
		}
	},
	created() {
		var id = this.$route.params.id

		if (id == undefined || id == '' || id == null) {
			id = sessionStorage.getItem('id')
		}
		// 获取资讯分类列表接口
		this.$axios
			.get('/pub/msgClassify/list')
			.then((res) => {
				console.log('data', res.data);
				this.msgClassify = res.data.list;
				//获取资讯详情接口
				this.$axios.get('/mseManage/list', {
					params: {
						id: id
					}
				}).then(res => {
					this.informationArr = res.data.list[0];
					this.labelArr = this.informationArr.classify.split(',');
					var newlabel = [];
					this.labelArr.forEach(item => {
						this.msgClassify.forEach(it => {
							if (item == it.id) {
								newlabel.push(it.calss_name);
							}
						})
					})
					this.labelArr = newlabel;
					console.log(newlabel);
				}).catch(err => {
					console.log(err)
				})
				// 获取资讯列表接口
				this.$axios
					.get('/mseManage/list')
					.then((res) => {
						var data = [];
						if (res.data.list) {
							res.data.list.forEach(item => {
								if (item.id != id) {
									item.picture_url = this.baseUrl + item.picture_url;
									data.push(item);
								}
							})
						}
						this.dataArr = data;
						this.recommended = data;
						this.dataArr.forEach((item, index) => {
							item.classify = item.classify.split(',');
							var newlabel = [];
							item.classify.forEach(it => {
								this.msgClassify.forEach(i => {
									if (it == i.id) {
										newlabel.push({
											id: index,
											name: i.calss_name
										});
									}
								})
							})
							item.classify = [];
							for (var i = 0; i < newlabel.length; i++) {
								if (index == newlabel[i].id) {
									item.classify.push(newlabel[i].name);
								}
							}

						})
						console.log('data', this.dataArr);
					})
					.catch((err) => {
						console.log(err)
					})
			})
			.catch((err) => {
				console.log(err)
			})
	},
	methods: {
		goLast(item) {
			//获取资讯详情接口
			this.$axios.get('/mseManage/list', {
				params: {
					id: item.id
				}
			}).then(res => {
				console.log(res.data.list[0])
				this.informationArr = res.data.list[0]
				this.labelArr = this.informationArr.classify.split(',');
				var newlabel = [];
				this.labelArr.forEach(item => {
					this.msgClassify.forEach(it => {
						if (item == it.id) {
							newlabel.push(it.calss_name);
						}
					})
				})
				this.labelArr = newlabel;
				this.getData(item.id);
				document.scrollingElement.scrollTop = 0;
				document.scrollingElement.scrollLeft = 0;
			}).catch(err => {
				console.log(err)
			})
		},
		getData(id) {
			// 获取资讯列表接口
			this.$axios
				.get('/mseManage/list')
				.then((res) => {
					console.log('list', res.data.list);
					var data = [];
					if (res.data.list) {
						res.data.list.forEach(item => {
							if (item.id != id) {
								item.picture_url = this.baseUrl + item.picture_url;
								data.push(item);
							}
						})
					}
					this.dataArr = data;
					this.recommended = data;
					this.dataArr.forEach((item, index) => {
						item.classify = item.classify.split(',');
						var newlabel = [];
						item.classify.forEach(it => {
							this.msgClassify.forEach(i => {
								if (it == i.id) {
									newlabel.push({
										id: index,
										name: i.calss_name
									});
								}
							})
						})
						item.classify = [];
						for (var i = 0; i < newlabel.length; i++) {
							if (index == newlabel[i].id) {
								item.classify.push(newlabel[i].name);
							}
						}

					})
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}
}
</script>

<style scoped>
body,
html {
	margin: 0;
	border: 0;
	padding: 0;
	width: 100%;
}

.main {
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
}

.row {
	width: 1200px;
	margin: 0 auto;
}

.row1 {
	width: 100%;
}

.row1-son {}

.row1-son-main {
	width: 100%;
	display: flex;
	justify-content: space-between;

}

.row1-son-main>ol {
	padding-left: 10px;
}

.row1-son-main>ol>li {
	width: 65%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 30px;
	cursor: pointer;
}

/*.row1-son-left {*/
/*  padding: ;*/
/*}*/

.row1-son-right {
	margin-top: 72px;
	width: 352px;
	height: 256px;
	background: #FFFFFF;
	border: 1px solid #E9EBF3;
	padding: 16px;

}

.row1-son-title {
	width: 100%;
	text-align: center;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #20242B;
}

.row1-son-main {
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #737C8B;
}

.row1-son-main>ul>li {
	padding: 0 0 16px 0;
	line-height: 25px;
}

.row1-son-left-title {
	margin-top: 70px;
	font-size: 30px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #20242B;
	width: 740px;
}

.row1-son-left-date {
	margin-top: 16px;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #737C8B;
}

.row1-son-left-main {
	margin-top: 34px;
	width: 740px;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: 26px;
}

.row1-son-lebal {
	margin-top: 52px;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #363F4E;
	display: flex;
	justify-content: left;
	align-items: center;
}

.row1-son-btn {
	width: 68px;
	height: 32px;
	border-radius: 2px;
	border: 1px solid #737C8B;
	text-align: center;
	line-height: 32px;
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #737C8B;
	margin-left: 20px;
}

.row1-son-last,
.row1-son-next {
	width: 100%;
	display: flex;
	justify-content: left;
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #737C8B;
	margin-bottom: 16px;
	cursor: pointer;
}

.row1-son-last {
	margin-top: 40px;
}

.row2 {
	width: 100%;
	background: #F7F8FB;
}

.row2-son {
	padding-bottom: 20px;
}

.row2-son-title {
	width: 100%;
	font-size: 24px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #737C8B;
	padding-top: 78px;
	padding-bottom: 41px;
}

.row2-son-left-box {
	width: 924px;
	height: 196px;
	background: #FFFFFF;
	box-shadow: 0px 1px 20px 0px rgba(115, 124, 139, 0.2);
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
}

.row2-son-left-box-img {
	width: 30%;
	height: 100%;
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.row2-son-left-box-img>img {
	width: 288px;
}

.row2-son-left-box-text {
	width: 64%;
	position: relative;
}

.row2-son-left-box-text-title {
	padding-top: 24px;
	font-size: 20px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #4285F4;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.row2-son-left-box-text-main {
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #737C8B;
	width: 90%;
	text-overflow: ellipsis;
	padding-top: 17px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.row2-son-left-box-text-date {
	padding-top: 17px;
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #737C8B;
}

.row2-son-left-box-btn {
	width: 100%;
	display: flex;
	justify-content: left;
	margin-top: 39px;
}

.row2-son-left-box-btn>div {
	width: 72px;
	height: 32px;
	background: #FFFFFF;
	border: 1px solid #DDDFE1;
	border-radius: 4px;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #737C8B;
	text-align: center;
	line-height: 32px;
	margin-right: 12px;
	position: absolute;
	bottom: 12px;
}
</style>
