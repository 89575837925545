<template>
	<div class="main">
		<div class="row1">
			<div class="row row1-son">
				<div class="row1-son-title">
					{{infoArr.name?infoArr.name : '智慧解决方案'}}
				</div>
				<div class="row1-son-main">
					<div class="row1-son-main-text">
						分享到
					</div>
					<div class="row-son-main-icon">
						<img src="../../../static/img/icon-dd.png" alt="">
					</div>
					<div class="row-son-main-icon">
						<img src="../../../static/img/icon-wx.png" alt="">
					</div>
					<div class="row-son-main-icon">
						<img src="../../../static/img/icon-wb.png" alt="">
					</div>
					<div class="bar">
					</div>
					<div class="row-son-main-icon">
						<img src="../../../static/img/icon-dz.png" alt="">
						<div class="praise">
							0
						</div>
					</div>
					<div class="bar">
					</div>
					<div class="row-son-main-icon">
						<img src="../../../static/img/icon-wd.png" alt="">
						<div class="row-son-main-icon-text">
							关注帝天微信号
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row2">
			<div class="row row2-son">
				<div class="row2-son-left">
					<img :src="infoArr.bgImg" alt="">
				</div>
				<div class="row2-son-right">
					<div class="row2-son-right-title">
						{{infoArr.title}}
					</div>
					<div class="row2-son-right-concept">
						简介 : {{infoArr.main}}
					</div>
					<div class="row2-son-right-advantage">
						优势 : {{planInfo.advantage}}
					</div>
					<div class="row2-son-right-case" v-if="infoArr.coppany">
						<div>
							相关案例:
						</div>
						<div class="row2-son-right-case-company" v-for="(item,index) in planInfo.coppany" :key="index">
							{{item}}
						</div>
					</div>
					<div class="row2-son-right-btn" @click="goYuyue()">
						预约交流
					</div>
				</div>
			</div>
		</div>
		<div class="row3">
			<div class="row row3-son">
				<div class="row3-son-box1">
					<div class="row3-son-box1-title">
						政务管理面临的问题
					</div>

					<div class="row3-son-box1-main">
						<div v-for="item in 4" style="margin:67px 0 123px 0;">
							<div class="row3-son-box1-main-img">
								<img src="../../../static/img/i-ythgkn.png" alt="">
							</div>
							<div class="row3-son-box1-main-label">
								一体化管控难
							</div>
							<div class="row3-son-box1-main-text">
								涵盖多级下属机构、多个部门，政务一体化管控困难
							</div>
						</div>

					</div>
				</div>
				<div class="row3-son-box2">
					<div class="row3-son-box2-title">
						六大技术特点
					</div>
					<div class="row3-son-box2-main">
						<div class="row3-son-box2-main-box" v-for="item in characteristicInfo">
							<div class="row3-son-box2-main-box-left">
								<img :src="item.img" />
							</div>
							<div class="row3-son-box2-main-box-right">
								<div class="row3-son-box2-main-box-right-title">
									{{item.title}}
								</div>
								<div class="row3-son-box2-main-box-right-main">
									{{item.text}}
								</div>
							</div>
						</div>
					</div>
					<div class="row3-son-box2-title">
						六大应用场景
					</div>
					<div class="scenario-box">
						<div class="scenario-box-left">
							<ul class="scenario-box-left-ul">
								<li @mouseover="Click(0)" :class=" index == 0 ?  'scenario-box-left-ul-li scenario-box-left-ul-li-hover' : 'scenario-box-left-ul-li'">集团型门户平台</li>
								<li @mouseover="Click(1)" :class=" index == 1 ?  'scenario-box-left-ul-li scenario-box-left-ul-li-hover' : 'scenario-box-left-ul-li'">流程优化</li>
								<li @mouseover="Click(2)" :class=" index == 2 ?  'scenario-box-left-ul-li scenario-box-left-ul-li-hover' : 'scenario-box-left-ul-li'">集团型知识管理平台</li>
								<li @mouseover="Click(3)" :class=" index == 3 ?  'scenario-box-left-ul-li scenario-box-left-ul-li-hover' : 'scenario-box-left-ul-li'">战略项目全周期管理</li>
								<li @mouseover="Click(4)" :class=" index == 4 ?  'scenario-box-left-ul-li scenario-box-left-ul-li-hover' : 'scenario-box-left-ul-li'">共享服务中心</li>
								<li @mouseover="Click(5)" :class=" index == 5 ?  'scenario-box-left-ul-li scenario-box-left-ul-li-hover' : 'scenario-box-left-ul-li'">统一移动办公平台</li>
							</ul>
						</div>
						<div class="scenario-box-right">
							<div class="scenario-box-right-tltie">
								{{ulArr[index].title}}
							</div>
							<div class="scenario-box-right-text">
								{{ulArr[index].text}}
							</div>
							<div class="scenario-box-right-img">
								<img :src="ulArr[index].img" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				index : 0 , //ul下标
				planInfo: {
					title: '智慧政务解决方案',
					concept: '帝天智慧政务解决方案,整合多维度的集团型门户平台、全周期集团战略项目管理平台、多领域集团共享服务平台、一体化集团流程管控平台、体系化集团知识管理平台，打造灵活平台,高效的智慧办公体验。',
					advantage: '政务一体化管控、全流程在线管理、战略项目全程化管理、跨组织知识经验共享',
					coppany: [
						'中铁十七局', '康佳集团', '海信集团', '云图控股'
					]
				}, // 方案详情
				characteristicInfo: [{
						title: '易拓展',
						img: require('../../../static/img/i-ytz.png') ,
						text: '平台具备开放性与扩展性，支持地产行业各类管理和业务应用的快速扩展'
					},
					{
						title: '多组件',
						img: require('../../../static/img/i-dzj.png'),
						text: '成熟的集成组件，支持与各类业务系统进行用户、数据、流程、门户深度集成'
					}, {
						title: '智能化',
						img: require('../../../static/img/i-znh.png'),
						text: '基于AI的知识图谱、智能交互技术，实现智慧化知识挖掘、智能化知识服务'
					},
					{
						title: '集团化',
						img: require('../../../static/img/i-jth.png'),
						text: '集团化分级授权管理、多语言支持，满足地产行业集团化、国际发展需求'
					},
					{
						title: '高安全',
						img: require('../../../static/img/i-gaq.png'),
						text: '系统及、应用级、附件级安全机制，保障各类信息、数据、知识安全'
					}, {
						title: '移动化',
						img: require('../../../static/img/i-ydh.png'),
						text: '应用全面移动化，内外部协作、沟通、分享无界限'
					}
				], //技术特点
				ulArr: [{
						title: '人事、财务等共享，助力企业提效降本',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},
					{
						title: '2',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},
					{
						title: '3',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},
					{
						title: '4',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},
					{
						title: '5',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},
					{
						title: '6',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},

				], //ul数组
				infoArr:[],
			}
		},
		created() {
			var info = JSON.parse(sessionStorage.getItem('PlanDetails'));
			var arr = this.$route.params.name;
			if(arr){
				this.infoArr = arr;
			}else{
				this.infoArr = info;
			}
			console.log(this.infoArr);
		},
		methods: {
			// 点击预约跳转到预约表单位置
			goYuyue() {
				window.scrollTo(0, document.documentElement.scrollHeight - document.documentElement.clientHeight)
			},
			Click(index) {
				this.index = index;
			}
		}

	}
</script>

<style scoped>
	body,html{
		margin: 0;
		border: 0;
		padding: 0;
		width: 100%;
	}
	.main{
		width: 100%;
		max-width: 1920px;
		margin:  0 auto;
	}
	.row {
		margin: 0 auto;
		width: 1200px;
	}

	.row1 {
		width: 100%;
		background-color: #FFFFFF;
		height: 192px;
	}

	.row1-son-title {
		padding-top: 58px;
		width: 100%;
		height: 34px;
		font-size: 36px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #363F4E;
	}

	.row1-son-main {
		display: flex;
		justify-content: left;
		align-items: center;
		margin-top: 32px;
	}

	.row-son-main-icon>img {
		width: 16px;
	}

	.row1-son-main-text {

		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #363F4E;
	}

	.row-son-main-icon {
		margin-left: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.bar {
		margin-left: 20px;
		width: 1px;
		height: 21px;
		background: #E9EBF3;
	}

	.praise {
		margin-left: 10px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #363F4E;
	}

	.row-son-main-icon-text {
		margin-left: 10px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #363F4E;
	}

	.row2 {
		width: 100%;
		height: 364px;
		background: #F7F8FB;
	}

	.row2-son {
		height: 100%;
		display: flex;
		justify-content: space-between;
	}

	.row2-son-left {
		display: flex;
		justify-content: left;
		align-items: center;
		height: 100%;
	}

	.row2-son-left>img {
		height: 280px;
	}

	.row2-son-right {
		width: calc(100% - 550px);
	}

	.row2-son-right-title {
		font-size: 24px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #20242B;
		padding-top: 45px;
	}

	.row2-son-right-concept {
		padding-top: 25px;
		width: 665px;
		/* height: 75px; */
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
		line-height: 26px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	.row2-son-right-advantage {
		padding-top: 20px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
		width: 665px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
	}

	.row2-son-right-case {
		padding-top: 20px;
		width: 665px;
		font-size: 16px;
		height: 41px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
		text-overflow: ellipsis;
		overflow: hidden;
		/* 		display: flex;
		justify-content: left; */
	}

	.row2-son-right-case>div {
		display: inline-block;
	}

	.row2-son-right-case-company {
		width: 100px;
		color: #4285F4;

	}

	.row2-son-right-btn {
		width: 120px;
		height: 40px;
		background: #4285F4;
		text-align: center;
		line-height: 40px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 62px;
	}

	.row3-son-box1 {
		margin-top: 68px;
		width: 100%;
	}

	.row3-son-box1-title {
		width: 100%;
		text-align: center;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #20242B;
	}

	.row3-son-box1-main {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.row3-son-box1-main-label {
		width: 100%;
		text-align: center;
	}

	.row3-son-box1-main-img {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.row3-son-box1-main-img>img {
		width: 44px;
	}

	.row3-son-box1-main-text {
		margin: 0 auto;
		width: 216px;
		height: 42px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #747D8C;
		text-align: center;
		line-height: 26px;
	}

	.row3-son-box2 {
		width: 100%;
	}

	.row3-son-box2-title {
		width: 100%;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #20242B;
		text-align: center;
		margin-bottom: 56px;
	}

	.row3-son-box2-main {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 60px;
	}

	.row3-son-box2-main-box {
		width: 388px;
		height: 188px;
		background: #FFFFFF;
		border: 1px solid #E9EBF3;
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
	}

	.row3-son-box2-main-box-left {
		width: 20%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.row3-son-box2-main-box-left>img {
		width: 44px;
	}

	.row3-son-box2-main-box-right {
		width: 70%;
		height: 100%;
	}

	.row3-son-box2-main-box-right-title {
		padding-top: 45px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242B;
	}

	.row3-son-box2-main-box-right-main {
		padding-right: 40px;
		padding-top: 20px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
	}

	.scenario-box {
		width: 100%;
		height: 580px;
		display: flex;
		justify-content: space-between;
	}

	.scenario-box-left {
		width: 200px;
		height: 580px;
		background: #F7F8FB;
		/* border: 1px solid #E9EBF3; */
	}

	.scenario-box-left-ul {
		width: 100%;
		height: 58px;
		text-align: center;
		line-height: 58px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
		padding-left: 0;
		margin: 0;
	}

	.scenario-box-left-ul-li {
		list-style: none;
		border-left: 2px solid #FFFFFF;
	}

	.scenario-box-left-ul-li-hover {
		/* border-right: 1px solid #FFFFFF; */
		background-color: #FFFFFF;
		border-left: 2px solid #4285F4;
	}

	.scenario-box-right {
		width: 1198px;
		padding: 40px;
	}

	.scenario-box-right-tltie {
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #111111;
	}

	.scenario-box-right-text {
		margin-top: 25px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242B;
	}

	.scenario-box-right-img {
		margin-top: 50px;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.scenario-box-right-img>img {
		height: 336px;
	}
</style>
