<template>
	<div class="main">
		<div class="row1">
			<div class="row row1-son">
				<div class="row1-son-title">
					{{moralExampleArr.shopManage?moralExampleArr.shopManage.name : companyName }}
				</div>
				<div class="row1-son-main">
					<div class="row1-son-main-text">
						分享到
					</div>
					<div class="row-son-main-icon">
						<img src="../../../static/img/icon-dd.png" alt="">
					</div>
					<div class="row-son-main-icon">
						<img src="../../../static/img/icon-wx.png" alt="">
					</div>
					<div class="row-son-main-icon">
						<img src="../../../static/img/icon-wb.png" alt="">
					</div>
					<div class="bar">
					</div>
					<div class="row-son-main-icon">
						<img src="../../../static/img/icon-dz.png" alt="">
						<div class="praise">
							0
						</div>
					</div>
					<div class="bar">
					</div>
					<div class="row-son-main-icon">
						<img src="../../../static/img/icon-wd.png" alt="">
						<div class="row-son-main-icon-text">
							关注帝天微信号
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row2">
			<div class="row row2-son">
				<div class="row2-son-left">
					<img :src="moralExampleArr.pictureUrl">
				</div>
				<div class="row2-son-right">
					<div class="row2-son-right-row1">
						客户名称 : {{moralExampleArr.shopManage?moralExampleArr.shopManage.name : companyName }}
					</div>
					<div class="row2-son-right-row2">
						所属行业：{{ moralExampleArr.mainContent }}
					</div>
					<div class="row2-son-right-row3">
						核心需求：{{ moralExampleArr.technicalFeature }}
					</div>
					<div class="row2-son-right-row4">
						<div>
							使用产品：
						</div>
						<div>
							{{moralExampleArr.applicationScenarios}}
						</div>
					</div>
					<div class="row2-son-right-btn" @click="goYuyue">
						预约交流
					</div>
				</div>
			</div>
		</div>
		<div class="row3">
			<div class="row row3-son">
				<div class="row3-son-title">
					案例详情
				</div>
				<div class="row3-son-main">
					<img class="row3-son-main-icon" src="../../../static/img/td.png" />
					<div class="row3-son-main-text">
						<p v-html="moralExampleArr.particulars">
							{{ moralExampleArr.particulars ? moralExampleArr.particulars : '暂无内容' }}
						</p>
					</div>
					<div class="row3-son-main-img" v-if="moralExampleArr.qrCodeUrl">
						<img @mousemove="showCode" @mouseout="hideCode" src="../../../static/img/ewm.jpg" />
					</div>
					<div class="row3-son-main-code" v-if="moralExampleArr.qrCodeUrl">
						扫码查看
						<div class="qr-code" v-if="flag">
							<img :src="moralExampleArr.qrCodeUrl" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row5">
			<div class="row row5-son">
				<div class="row5-son-title">
					案例展示
				</div>
				<div class="row5-son-imgbox" v-for="(item,index) in imgArr">
					<img :src="item" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgArr: [], //案例图片
				moralExampleArr: [],
				companyName: '福建壹刻食品有限公司', //公司名称
				index: 0, //ul下标
				flag: false,
				ulArr: [{
						title: '人事、财务等共享，助力企业提效降本',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},
					{
						title: '流程优化',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},
					{
						title: '集团型知识管理平台',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},
					{
						title: '战略项目全周期管理',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},
					{
						title: '共享服务中心',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},
					{
						title: '统一移动办公平台',
						text: '提供包括财务共享、HR共享、IT共享、法务共享等多类共享服务中心建设，支撑资源高效配置与利用，助力企业提效降本。',
						img: require('../../../static/img/fagh.png')
					},

				], //ul数组
				reasonArr: [{
						img: require('../../../static/img/i-pt.png'),
						title: '大数据流程分析平台',
						text: '先引入大数据理念和技术，构建企业流程大数据分析平台，拉通各个业务系统数据，实现一体化流程数据分析'
					},
					{
						img: require('../../../static/img/i-gj.png'),
						title: '高效的流程数字化洞察工具（CT）',
						text: '宏观感知、微观洞察、智能推导、智能感知等先进流程分析工具（CT），通过数字化高效洞察企业流程问题'
					},
					{
						img: require('../../../static/img/i-tp.png'),
						title: '丰富流程优化体系',
						text: '行业首创流程大数据优化体系，20+流程分析优化场景，支撑企业流程效率优化、经营管理改善、业务生产周期缩短以及企业创新等'
					}
				] //选择帝天的理由数组
			}
		},
		created() {
			var id = this.$route.params.id
			if (id == undefined || id == '' || id == null) {
				id = sessionStorage.getItem('id')
			}
			this.$axios.get('/case/select', {
				params: {
					id: id
				}
			}).then(res => {
				this.moralExampleArr = res.data;
				this.moralExampleArr.pictureUrl = this.baseUrl + this.moralExampleArr.pictureUrl;
				this.moralExampleArr.qrCodeUrl = this.baseUrl + this.moralExampleArr.qrCodeUrl;
				var Arr = this.moralExampleArr.url.split(',');
				Arr.forEach(item => {
					this.imgArr.push(this.baseUrl + item);
				})				
			}).catch(err => {
				console.log(err)
			})
		},
		methods: {
			//点击预约跳转到预约表单位置
			goYuyue() {
				window.scrollTo(0, document.documentElement.scrollHeight - document.documentElement.clientHeight)
			},
			//鼠标移入显示二维码的方法
			showCode() {
				this.flag = true
			},
			//鼠标移出隐藏二维码的方法
			hideCode() {
				this.flag = false
			},
			//点击切换场景内容的方法
			Click(index) {
				this.index = index
			}
		}
	}
</script>

<style scoped>
	body,html{
		margin: 0;
		border: 0;
		padding: 0;
		width: 100%;
	}
	.main{
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
	}
	.row {
		width: 1200px;
		margin: 0 auto;
	}
	/*文字移入动画*/
	@keyframes topTextAnimate {
		0% {
			transform: translateY(-30px);
			opacity: 0;
		}
	
		100% {
			transform: translateY(0px);
			opacity: 1;
		}
	
	}

	@keyframes lefteaseinAnimate {
		0% {
			transform: translateY(-1000px);
			opacity: 0;
		}

		45% {
			transform: translateY(50px);
			opacity: 1;
		}

		65% {
			transform: translateY(-50px);
			opacity: 1;
		}

		100% {
			transform: translateY(0px);
			opacity: 1;
		}

	}

	.row1 {
		width: 100%;
		background-color: #FFFFFF;
		height: 192px;
	}

	.row1-son-title {
		padding-top: 58px;
		width: 100%;
		height: 34px;
		font-size: 36px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #363F4E;
	}

	.row1-son-main {
		display: flex;
		justify-content: left;
		align-items: center;
		margin-top: 32px;
	}

	.row-son-main-icon>img {
		width: 16px;
	}

	.row1-son-main-text {

		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #363F4E;
	}

	.row-son-main-icon {
		margin-left: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.bar {
		margin-left: 20px;
		width: 1px;
		height: 21px;
		background: #E9EBF3;
	}

	.praise {
		margin-left: 10px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #363F4E;
	}

	.row-son-main-icon-text {
		margin-left: 10px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #363F4E;
	}

	.row2 {
		width: 100%;
		height: 364px;
		background: #F7F8FB;
	}

	.row2-son {
		height: 100%;
		display: flex;
		justify-content: space-between;
	}

	.row2-son-left {
		height: 100%;
		display: flex;
		align-items: center;
	}

	.row2-son-left>img {
		height: 260px;
	}

	.row2-son-right {
		width: 690px;
		height: 100%;
	}

	.row2-son-right-row1 {
		padding-top: 50px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242B;
	}

	.row2-son-right-row2 {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
		margin-top: 25px;
	}

	.row2-son-right-row3 {
		margin-top: 25px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
	}

	.row2-son-right-row4 {
		margin-top: 25px;
		width: 100%;
		display: flex;
		justify-content: left;
	}

	.row2-son-right-row4>div:nth-child(1) {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
	}

	.row2-son-right-row4>div:nth-child(2) {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4285F4;
	}

	.row2-son-right-btn {
		margin-top: 63px;
		width: 120px;
		height: 40px;
		background: #4285F4;
		text-align: center;
		line-height: 40px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		cursor: pointer;
	}

	.row3 {
		width: 100%;
		height: 500px;
		background-image: url(../../../static/img/wg.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.row3-son-title {
		padding-top: 68px;
		width: 100%;
		text-align: center;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242B;
	}

	.row3-son-main {
		position: relative;
		margin-top: 55px;
		width: 100%;
		height: 320px;
		background: #FFFFFF;
		box-shadow: 0px 9px 30px 0px rgba(115, 124, 139, 0.1);
	}

	.row3-son-main-text {
		padding-top: 85px;
		margin: 0 auto;
		width: 975px;
		height: 69px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
		line-height: 26px;
	}

	.row3-son-main-img {
		margin-top: 54px;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.row3-son-main-code {
		position: relative;
		width: 100%;
		text-align: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #000000;
	}

	.row3-son-main-icon {
		position: absolute;
		width: 44px;
		right: 0;
		top: 2px;
		animation: lefteaseinAnimate 1s ease 1;
		animation-fill-mode: forwards;
	}

	.qr-code {
		top: -157px;
		left: 528px;
		position: absolute;
		width: 144px;
		height: 105px;
		/* box-shadow: 0px 10px 20px 0px rgba(115, 124, 139, 0.3); */
	}

	.qr-code>img {
		width: 120px;
	}

	.row4 {
		width: 100%;
		background-color: #FFFFFF;
	}

	.row4-son {}

	.row4-son-title {
		padding-top: 68px;
		width: 100%;
		text-align: center;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242B;
	}

	.row4-son-box {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.row4-son-box-card {
		width: 300px;
	}

	.row4-son-box-card-icon {
		margin-top: 54px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.row4-son-box-card-icon>img {
		width: 56px;
	}

	.row4-son-box-card-title {
		margin-top: 39px;
		margin-bottom: 26px;
		width: 100%;
		text-align: center;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #20242B;
	}

	.row4-son-box-card-text {
		margin: 0 auto;
		width: 256px;
		/* height: 69px; */
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
		line-height: 26px;
		padding-bottom: 73px;
	}

	.row5 {
		width: 100%;
		background: #F7F8FB;
		padding-bottom: 20px;
	}

	.row5-son {}

	.row5-son-title {
		padding-top: 68px;
		width: 100%;
		text-align: center;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242B;
		padding-bottom: 67px;
	}

	.row5-son-imgbox {
		width: 100%;
		margin: 20px 0;
	}

	.row5-son-imgbox>img {
		width: 1200px;
	}

	.row6-son-box2 {
		width: 100%;
	}

	.row6-son-box2-title {
		margin-top: 68px;
		width: 100%;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #20242B;
		text-align: center;
		margin-bottom: 56px;
	}

	.row6-son-box2-main {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 60px;
	}

	.row6-son-box2-main-box {
		width: 388px;
		height: 188px;
		background: #FFFFFF;
		border: 1px solid #E9EBF3;
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
	}

	.row6-son-box2-main-box-left {
		width: 20%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.row6-son-box2-main-box-left>img {
		width: 44px;
	}

	.row6-son-box2-main-box-right {
		width: 70%;
		height: 100%;
	}

	.row6-son-box2-main-box-right-title {
		padding-top: 45px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242B;
	}

	.row6-son-box2-main-box-right-main {
		padding-right: 40px;
		padding-top: 20px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
	}

	.scenario-box {
		width: 100%;
		height: 580px;
		display: flex;
		justify-content: space-between;
	}

	.scenario-box-left {
		width: 200px;
		height: 540px;
		background: #F7F8FB;
		/* border: 1px solid #E9EBF3; */
	}

	.scenario-box-left-ul {
		width: 100%;
		height: 58px;
		text-align: center;
		line-height: 58px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
		padding-left: 0;
		margin: 0;
	}

	.scenario-box-left-ul-li {
		list-style: none;
		border-left: 2px solid #FFFFFF;
	}

	.scenario-box-left-ul-li-hover {
		/* border-right: 1px solid #FFFFFF; */
		background-color: #FFFFFF;
		border-left: 2px solid #4285F4;
	}

	.scenario-box-right {
		width: 1198px;
		height: 540px;
		animation: topTextAnimate 1s ease 1;
		animation-fill-mode: forwards;
		transition: all 0.6s;
	}

	.scenario-box-right-tltie {
		padding: 40px 0 0 40px;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #111111;
	}

	.scenario-box-right-text {
		padding-left: 40px;
		margin-top: 25px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242B;
	}

	.scenario-box-right-img {
		margin-top: 50px;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.scenario-box-right-img>img {
		height: 336px;
	}

	.row7 {
		width: 100%;
		height: 516px;
		background: #F7F8FB;
	}

	.row7-son-title {
		width: 100%;
		text-align: center;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #20242B;
		padding-top: 68px;
		padding-bottom: 48px;
	}

	.row7-son-main {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.row7-son-main-box {
		width: 288px;
		height: 288px;
		background: #FFFFFF;
	}

	.row7-son-main-box-title {
		padding-top: 49px;
		padding-left: 32px;
		font-size: 56px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #E9EBF3;
	}

	.row7-son-main-box-main {
		padding-top: 20px;
		width: 224px;
		margin: 0 auto;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242B;
		line-height: 26px;
	}
</style>
