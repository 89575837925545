<template>
	<div class="main">
		<div class="row1">
			<img style="width: 100%;" src="../../../static/img/banner/banner_jjfa.9a65fdc.jpg" />
			<div>
				<p>
					解决方案
				</p>
				<p>
					针对不同领域的业务特性和管理需求，提供针对性的解决方案和产品服务
				</p>
			</div>
		</div>
		<div class="row2">
			<div class="row">
				<div class="row2-main">
					<el-select v-model="value" placeholder="请选择公司所在的行业">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<el-select v-model="value" placeholder="请选择公司规模">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<div class="row2-btn">
						预约交流专属方案
					</div>
				</div>
			</div>
		</div>
		<div class="row3">
			<div class="row">
				<div class="row3-son">
					<div class="row3-son-title">
						热门方案
					</div>
					<div style="display: flex; justify-content: center;margin-top: 48px;margin-bottom: 48px;">
						<div class="row3-son-btn row3-son-btn1">
							热门行业
						</div>
						<div class="row3-son-btn row3-son-btn2">
							热门场景
						</div>
					</div>
					<div class="accordion">
						<transition name="fade" v-for="(item,index) in accordionArr" :key="index">
							<div :class="item.show == true ? 'accordion-box' :'accordion-box1' "
								@mouseover="showAccordion(index)"
								:style="item.show == false ? {background: 'url('+item.url+')'}: {background: 'url('+item.bgImg+')'}">
								<!-- <img v-if="item.show" :src="item.bgImg" /> -->
								<div v-if="item.show" class="accordion-box-title">
									{{item.title}}
								</div>
								<div v-if="item.show" class="accordion-box-content">
									{{item.main}}
								</div>
								<div v-if="item.show" class="accordion-box-btn" @click="goPlanDetails(item)">
									了解详情
								</div>
								<div v-if="!item.show" class="accordion-box1-box">
									<img class="accordion-box1-img" :src="item.icon" />
								</div>
								<div v-if="!item.show" class="accordion-box1-text">
									{{item.name}}
								</div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</div>
		<div class="row4">
			<div class="row">
				<div class="row4-son">
					<div class="row4-son-title">
						帝天整体解决方案
					</div>
					<div>
						<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
							@select="handleSelect">
							<el-menu-item index="0">区域</el-menu-item>
							<!-- 							<el-menu-item index="1">
								<template slot="title">
									通用
								</template>
							</el-menu-item>
							<el-menu-item index="2">行业</el-menu-item> -->
						</el-menu>
					</div>
					<div v-if="activeIndex == key" v-for="(item,key) in 2" class="row4-son-box">
						<div v-for="(it,index) in planArr" :key="index">
							<div class="row4-son-box-left">
								<img :src="it.url" />
							</div>
							<div class="row4-son-box-right">
								<div class="row4-son-box-right-title">
									{{it.title}}
								</div>
								<div class="row4-son-box-right-content">
									{{it.content}}
								</div>
							</div>
						</div>
					</div>
					<div class="row4-son-pages">
						<el-pagination background layout="prev, pager, next" :total="6" :pageSize="6">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<div class="row5">
			<div class="row">
				<div class="row5-son">
					<div class="row5-son-title">
						联系我们，获取您所需的更多解决方案
					</div>
					<div class="phone-icon">
						<img src="../../../static/img/phone.png" />
						<div>
							13075868963
						</div>
					</div>
					<div class="row5-son-btn">
						更多联系方式
					</div>
				</div>
			</div>
		</div>
		<div class="row6">
			<div class="row">
				<div class="row6-son">
					<div class="row6-son-title">
						热门方案
					</div>
					<div style="display: flex; justify-content: center;margin-top: 48px;margin-bottom: 48px;">
						<div class="row3-son-btn row3-son-btn1">
							热门行业
						</div>
						<div class="row3-son-btn row3-son-btn2">
							热门场景
						</div>
					</div>
					<div class="row6-son-content">
						<div class="row6-son-box" v-for="item in 8">
							<div class="row6-son-box-icon">
								<img src="../../../static/img/logo_1Cake.jpg" />
							</div>
							<div class="row-son-box-btn">
								食品
							</div>
						</div>
					</div>
					<div class="row6-son-btn">
						<div class="row6-son-btn-left">
							<router-link to="/moralExample" class='ative'>更多案例</router-link>
						</div>
						<div class="row6-son-btn-right">
						</div>
						<img src="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				flag: true,
				value: '',
				activeIndex: '0',
				options: [],
				accordionArr: [{
						url: require('../../../static/img/zhzw-wzk.jpg'),
						icon: require('../../../static/img/zhzw-i.png'),
						name: '智慧政务',
						title:'智慧政务-------构建政务公开平台',
						show: true,
						bgImg: require('../../../static/img/fa/zhzw-zk.jpg'),
						main: '通过规范政务服务事项，实现政务服务线上和线下多渠道的统一申请、统一受理、集中办理、统一反馈和全流程监督等功能，有效解决办事系统繁杂、数据共享不畅、业务协同不足等问题'
					},
					{
						url: require('../../../static/img/zhdj-wzk.jpg'),
						icon: require('../../../static/img/zhdj-i.png'),
						name: '智慧党建',
						title:'智慧党建------赋能“党、纪、团、群”工作更高效、更智慧',
						show: false,
						bgImg: require('../../../static/img/fa/zhdj-zk.jpg'),
						main: '有效支撑跨地域覆盖、方便基层工作落实；提供互动交流平台，多样的宣传教育和组织生活，加强组织吸引力和凝聚力'
					},
					{
						url: require('../../../static/img/zhyl-wzk.jpg'),
						icon: require('../../../static/img/zhyl-i.png'),
						name: '智慧医疗',
						title:'智慧医疗------助力医院管理效率提高80%',
						show: false,
						bgImg: require('../../../static/img/fa/zhyl-zk.jpg'),
						main: '全新智慧OA，让全院员工移动办公体验改善、“三重一大”管理更科学，多类费用管控更高效。'
					},
					{
						url: require('../../../static/img/zhyq-wzk.jpg'),
						icon: require('../../../static/img/zhyq-i.png'),
						name: '智慧园区',
						title:'智慧园区------促进园区资源合理整合',
						show: false,
						bgImg: require('../../../static/img/fa/zhyq-zk.jpg'),
						main: '建立园区大数据平台管理平台和产业地图，提升园区智慧化管理水平，提升运营效率，加速业务敏捷创新，提升服务体验，掌握园区企业发展动态，扶持企业健康快速发展。'
					},
					{
						url: require('../../../static/img/zhcs-wzk.jpg'),
						icon: require('../../../static/img/zhcs-i.png'),
						name: '智慧城市',
						title:'智慧城市------用数据智能构建“城市大脑',
						show: false,
						bgImg: require('../../../static/img/fa/zhcs-zk.jpg'),
						main: '以数据为核心建设城市运营指挥中心，用数据智能技术推动城市管理手段、管理模式、管理理念创新，加快智慧城市建设。'
					},
				], //手风琴数组
				planArr: [{
						url: require('../../../static/img/icon.png'),
						title: '福州',
						content: '平台具备开放性与扩展性，支持地产行业各类管理和业务应快速扩展'
					},
					{
						url: require('../../../static/img/icon.png'),
						title: '厦门',
						content: '平台具备开放性与扩展性，支持地产行业各类管理和业务应快速扩展'
					},
					{
						url: require('../../../static/img/icon.png'),
						title: '南平',
						content: '平台具备开放性与扩展性，支持地产行业各类管理和业务应快速扩展'
					},
					{
						url: require('../../../static/img/icon.png'),
						title: '三明',
						content: '平台具备开放性与扩展性，支持地产行业各类管理和业务应快速扩展平台具备开放性与扩展性，支持地产行业各类管理和业务应快速扩展'
					},
					{
						url: require('../../../static/img/icon.png'),
						title: '南宁',
						content: '平台具备开放性与扩展性，支持地产行业各类管理和业务应快速扩展'
					},
					{
						url: require('../../../static/img/icon.png'),
						title: '南昌',
						content: '平台具备开放性与扩展性，支持地产行业各类管理和业务应快速扩展'
					}
				] //解决方案数组

			}
		},
		created() {

		},
		methods: {
			handleSelect() {

			},
			goPlanDetails(item) {
				this.$router.push({
					name: 'PlanDetails',
					params: {
						name: item
					}
				})
				sessionStorage.setItem('PlanDetails', JSON.stringify(item));
			},
			showAccordion(key) {
				console.log(key);
				this.accordionArr.forEach((item, index) => {
					if (index == key) {
						item.show = true;
					} else {
						item.show = false;
					}
				})
			}
		}
	}
</script>

<style scoped>
	body,html{
		margin: 0;
		border: 0;
		padding: 0;
		width: 100%;
	}
	.main{
		width: 100%;
		max-width: 1920px;
		margin:  0 auto;
	}

	.row {
		width: 1200px;
		margin: 0 auto;
	}

	.row1 {
		width: 100%;
		position: relative;
	}
	.row1>div {
		position: absolute;
		top: 20%;
		z-index: 9;
		color: #ffffff;
		width: 100%;
		text-align: center;
	}
	.row1>div>P:nth-child(1){
		font-size: 36px;
	}
	.row1>div>P:nth-child(2){
		font-size: 20px;
	}

	.row2 {
		width: 100%;
		background: #FFFFFF;
	}

	.row2-main {
		position: relative;
		top: -62px;
		width: 100%;
		height: 120px;
		background: #FFFFFF;
		box-shadow: 0px 6px 10px 0px rgba(115, 124, 139, 0.15);
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.row2-btn {
		width: 200px;
		height: 40px;
		background: #689DF6;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}

	.el-select {
		width: 360px;
	}

	.row3-son {
		margin-top: 38px;
		width: 100%;
		height: 600px;
		/* border: 1px solid #008BFA; */
	}

	.row3-son-title {
		width: 100%;
		text-align: center;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #20242B;
	}

	.row3-son-btn {
		width: 122px;
		height: 48px;
		text-align: center;
		line-height: 48px;
		background: #FFFFFF;
		/* border: 1px solid #4285F4; */
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4285F4;
	}

	.row3-son-btn1 {
		border-top: 1px solid #4285F4;
		border-bottom: 1px solid #4285F4;
		border-left: 1px solid #4285F4;
	}

	.row3-son-btn2 {
		border: 1px solid #4285F4;
	}

	.row3-son-btn:hover {
		background: #4285F4;
		color: #FFFFFF;
	}

	.accordion {
		margin-top: 48px;
		width: 100%;
		height: 360px;
		display: flex;
		justify-content: center;
	}

	.accordion>div {
		transition: all 0.6s;
	}

	.accordion-box {
		width: 480px;
		height: 360px;
		display: inline-block;
		background-color: #0000FF;
		position: relative;
	}

	.accordion-box>img {
		position: absolute;
		top: 0;

	}

	.accordion-box1 {
		width: 180px;
		height: 360px;
		display: flex;
		/* justify-content: center; */
		/* align-items: center; */
		flex-wrap: wrap;
		/* background-color: #13CE66; */
	}

	.accordion-box1-box {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.accordion-box1-img {
		width: 52px;
		height: 52px;
	}

	.accordion-box1-text {
		width: 100%;
		text-align: center;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #FFFFFF;
	}

	.accordion-box-title {
		position: absolute;
		top: 85px;
		left: 41px;
		font-size: 22px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #FFFFFF;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	.accordion-box-content {
		position: absolute;
		top: 137px;
		left: 40px;
		width: 356px;
		/* height: 15px; */
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		margin-top: 15px;
		color: #FFFFFF;
	}

	.accordion-box-btn {
		position: absolute;
		top: 240px;
		left: 40px;
		width: 120px;
		height: 40px;
		background: #4285F4;
		text-align: center;
		line-height: 40px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		cursor: pointer;
	}

	.row4 {
		width: 100%;
	}

	.row4-son-title {
		margin-top: 60px;
		margin-bottom: 45px;
		width: 100%;
		text-align: center;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #20242B;
	}

	.el-menu-item {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #000;
	}

	.is-active {
		color: #4285F4 !important;
	}

	.row4-son-box {
		margin-top: 40px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.row4-son-box>div {
		margin-bottom: 20px;
		width: 388px;
		height: 188px;
		background: #FFFFFF;
		border: 1px solid #E9EBF3;
		display: flex;
		justify-content: space-between;
	}

	.row4-son-pages {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 40px;
		margin-top: 11px;
	}

	.row4-son-box-left {
		width: 30%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.row4-son-box-left>img {
		width: 44px;
	}

	.row4-son-box-right {
		width: 65%;
	}

	.row4-son-box-right-title {
		padding-top: 30px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #20242B;
	}

	.row4-son-box-right-content {
		width: 210px;
		padding-top: 20px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
		line-height: 26px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	.row5 {
		width: 100%;
		height: 300px;
		background-image: url(../../../static/img/bg@2x.0bea926.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.row5-son-title {
		width: 100%;
		text-align: center;
		padding-top: 67px;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #FFFFFF;
	}

	.phone-icon {
		width: 100%;
		padding-top: 54px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.phone-icon>div {
		font-size: 30px;
		font-family: Arial;
		font-weight: bold;
		color: #FFFFFF;
	}

	.phone-icon>img {
		width: 20px;
		margin-right: 10px;
	}

	.row5-son-btn {
		width: 100%;
		padding-top: 53px;
		text-align: center;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.row6 {
		width: 100%;
		background: #F7F8F8;
	}

	.row6-son-title {
		padding-top: 68px;
		text-align: center;
		width: 100%;
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #20242B;
	}

	.row6-son-content {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.row6-son-box {
		width: 299px;
		height: 224px;
		background: #FFFFFF;
		margin-bottom: 1px;

	}

	.row6-son-box:hover {
		box-shadow: 0px 5px 10px 0px rgba(220, 221, 221, 0.8);
	}

	.row6-son-box-icon {
		padding-top: 60px;
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: 28px;
	}

	.row6-son-box-icon>img {
		width: 108px;
		margin: 0 auto;
	}

	.row-son-box-btn {
		width: 44px;
		height: 36px;
		text-align: center;
		line-height: 36px;
		background: #FFFFFF;
		border: 1px solid #B9C5D2;
		border-radius: 3px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #737C8B;
		margin: 0 auto;
	}

	.row6-son-btn {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 44px;
		padding-bottom: 56px;
	}

	.row6-son-btn-left {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #4285F4;
		margin-right: 10px;

	}

	a {
		text-decoration: none;
		color: #4285F4;
	}

	.ative {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #4285F4;
		text-decoraction: none;
	}

	.row6-son-btn-right {
		width: 12px;
		height: 12px;
		border-top: 1px solid #4285F4;
		border-right: 1px solid #4285F4;
		transform: rotate(45deg);
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 2s
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0
	}
</style>
