<template>
	<div class="main">
		<div class="row8">
			<div class="row">
				<div class="row8-son">
					<div>预约交流获取完整方案</div>
					<div class="form-box">
						<div class="line1">
							<span>姓名</span>
							<input type="text" placeholder="请输入您的姓名" v-model="name" />
						</div>
						<div class="line2">
							<span>手机</span>
							<input type="text" placeholder="请输入11位手机号" maxlength="11" @blur="changePhone" v-model="phone" />
							<div @click="getCode" v-if="codeFlag">
								查看验证码
							</div>
							<img :src="imgCode" @click="getCode" v-if="!codeFlag" />
							<input style="width: 170px" placeholder="请输入校验码" type="text" maxlength="6" v-model="code" />
							<p v-if="!flag">系统提示：手机号必填</p>
						</div>
						<div class="line1">
							<span>公司</span>
							<input type="text" placeholder="请输入贵公司姓名" v-model="companyName" />
						</div>
						<div class="line4" @click="goReservation">立即预约</div>
						<div class="line3">(您提交的信息将会受到严格保密)</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="row">
				<div class="footer-box">
					<div class="footer-box-son1">
						<img src="../../static/img/logo-ditian-w.png" />
					</div>
					<div class="footer-box-son2">
						<div class="footer-box-son2-box">
							<div class="title">商务合作</div>
							<div class="text">13075868963</div>
						</div>
						<div class="footer-box-son2-box">
							<div class="title">合作渠道</div>
							<div class="text">king@fzditian.com</div>
						</div>
						<div class="footer-box-son2-box">
							<div class="title">关于帝天</div>
							<div class="text">创始团队</div>
							<div class="text">大事记</div>
						</div>
						<div class="footer-box-son2-box">
							<div class="title">技术合作</div>
							<div class="text">社招</div>
							<div class="text">校招</div>
							<div class="text">实习热招</div>
						</div>
						<div class="footer-box-son2-box">
							<div class="title">加入我们</div>
							<div class="text">社招</div>
							<div class="text">校招</div>
							<div class="text">实习热招</div>
						</div>
					</div>
					<div class="footer-box-son3">
						<div class="title1">关注我们</div>
						<div class="footer-box-son3-img">
							<img src="../../static/img/ewm.png" alt="" />
						</div>
					</div>
				</div>
				<div class="footer-box1">
					<div class="footer-box1-son1">
						<div>福州 : 福州市鼓楼区工业路611号高新技术创业园4楼北6室</div>
						<div>电话 : 13075868963</div>
					</div>
					<div>
						<div>平潭 : 福建省平潭综合实验区中山大道中段288号8幢A座</div>
						<div>电话 : 13075868963</div>
					</div>
				</div>
				<div class="footer-box2">
					<div>
						安全中心 隐私声明 | 版权所有 @
						<span style="color: #ffffff">福州帝天信息科技有限公司 2001-2021</span>
						<span class="pointer" @click="goPage('https://beian.miit.gov.cn/')">闽ICP备15027127号-1</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			flag: true,
			name: "",
			code: "",
			phone: "",
			companyName: "",
			imgCode: '',
			codeFlag: true
		};
	},
	created() {
	},
	methods: {
		// 链接跳转
		goPage(url) {
			window.open(url);
		},
		changePhone(e) {
			this.flag = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
				this.phone
			);
		},
		//获取验证码
		getCode() {
			var that = this;
			if (!that.phone) {
				this.$message.error('请先输入手机号');
				return;
			}
			this.$axios({
				method: 'get',
				url: '/aoittManage/img?phone=' + that.phone,
				responseType: 'arraybuffer',
			}).then(re => {
				var base64 = btoa(new Uint8Array(re.data).reduce((data, byte) => data + String.fromCharCode(
					byte), ''));
				this.imgCode = "data:image/gif;base64," + base64;
				this.codeFlag = false;
				if (re.code == "error") {
					this.$message.error(re.msg);
				}
			}).catch(err => {
				console.log(err);
			});
		},
		//用户点击预约方法
		goReservation() {
			var that = this;
			if (!that.phone) {
				that.$message.error('请先输入手机号');
				return;
			} else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
				that.phone
			)) {
				this.$message.error('请输入正确手机号');
				return;
			}
			this.$axios
				.post("/aoittManage/backAdd", {
					clientName: that.name,
					phone: that.phone,
					companyName: that.companyName,
					inputRandomCode: that.code,
					headers: {
						'type': 'gw1'
					}
				})
				.then((res) => {
					console.log(res);
					if (res.status == 200) {
						if (res.data.code == "success") {
							this.$message({
								message: res.data.msg,
								type: "success",
							});
							that.code = '';
							that.phone = '';
							that.name = '';
							that.companyName = '';
							that.imgCode = '';
							this.codeFlag = !this.codeFlag;
						} else if (res.data.code == "error") {
							this.$message.error(res.data.msg);
						}
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
	},
};
</script>

<style scoped>
body,
html {
	margin: 0;
	border: 0;
	padding: 0;
	width: 100%;
}

.main {
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
}

.pointer {
	cursor: pointer;
}

.row {
	width: 1200px;
	margin: 0 auto;
}

.footer {
	width: 100%;
	height: 345px;
	background-color: #20242b;
}

.footer-box {
	width: 81%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #30343b;
	position: relative;
}

.footer-box-son1 {
	width: 220px;
	height: 175px;
	display: flex;
	align-items: center;
}

.footer-box-son1>img {
	width: 100%;
	height: 36px;
}

.footer-box-son2 {
	width: 70%;
	height: 175px;
	display: flex;
}

.footer-box-son2-box {
	width: 22%;
	height: 100%;
}

.footer-box-son2-box:nth-child(3) {
	margin-left: 20px;
}

.footer-box-son2-box:nth-child(4) {
	margin-left: 10px;
}

.footer-box-son2-box:nth-child(5) {
	width: 11%;
	margin-left: 10px;
}

.footer-box-son3 {
	position: absolute;
	height: 200px;
	top: 20px;
	right: -245px;
}

.footer-box-son3>div {
	width: 100%;
	text-align: center;
}

.title {
	margin-top: 20px;
	color: #ffffff;
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 20px;
}

.title1 {
	color: #ffffff;
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 20px;
	text-align: center;
}

.text {
	color: #787f8e;
	font-size: 12px;
	margin: 10px 0;
	cursor: pointer;
}

.footer-box-son3-img {
	margin: 0 auto;
}

.footer-box1 {
	margin-top: 22px;
	display: flex;
	justify-content: space-between;
}

.footer-box1>div {
	width: 100%;
}

.footer-box1>div>div {
	margin: 10px 0 5px 0;
	color: #8f98a8;
	font-size: 12px;
}

.footer-box2 {
	margin-top: 40px;
}

.footer-box2>div {
	margin: 5px 0;
	color: #8f98a8;
	font-size: 12px;
}

.row8 {
	width: 100%;
	height: 540px;
	background-image: url(../../static/img/bj-yy.jpg);
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.row8-son {
	width: 100%;
	text-align: center;
	color: #ffffff;
}

.row8-son>div:nth-child(1) {
	padding-top: 57px;
	padding-bottom: 27px;
	font-size: 30px;
	font-family: PingFang SC Medium;
	font-weight: 500;
	color: #ffffff;
}

.form-box {
	padding-top: 40px;
	width: 100%;
	height: 315px;
	background-color: #ffffff;
}

.line1 {
	margin: 10px 0 22px 0;
}

.line1>span {
	height: 50px;
	line-height: 50px;
	display: inline-block;
	margin-right: 20px;
	color: #000000;
}

.line1>input {
	box-sizing: border-box;
	width: 650px;
	height: 50px;
	padding: 14px 20px;
	font-size: 16px;
	background: #fff;
	border: 1px solid #e9ebf3;
	outline: none;
}

.line2 {
	/* height: 70px; */
	margin: 10px 0 20px 0;
	position: relative;
}

.line2>span {
	height: 50px;
	line-height: 50px;
	display: inline-block;
	margin-right: 20px;
	color: #000000;
}

.line2>div {
	display: inline-block;
	width: 100px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	color: rgb(117, 117, 117);
	cursor: pointer;
}

.line2>input {
	box-sizing: border-box;
	width: 370px;
	height: 50px;
	padding: 14px 20px;
	font-size: 16px;
	background: #fff;
	border: 1px solid #e9ebf3;
	outline: none;
}

.line2>img {
	cursor: pointer;
	vertical-align: top;
	height: 50px;
	width: 100px;
	text-align: center;
	border: none;
	background-color: transparent;
	font-size: 16px;
	color: #4285f4;
	outline: none;
}

.line2>p {
	position: absolute;
	color: #f0465c;
	left: 305px;
	top: 41px;
	text-align: left;
	font-size: 12px;
}

.line4 {
	width: 120px;
	height: 40px;
	background: #4285f4;
	border-radius: 2px;
	margin: 24px auto 11px auto;
	line-height: 40px;
	cursor: pointer;
}

.line3 {
	margin-top: 2px;
	font-size: 14px;
	color: #b9c5d2;
}
</style>
